import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Table, Input, Button, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
// import EmpCard from './Card';
import { ExportCSV } from "../Excel/ExportToCSV.tsx";
import SkeletonComp from "../components/SkeletonComp";
import Sidebar from '../components/Sidebar.jsx';


const ListEmployee = ({ handleFilter, filterDesignation }) => {
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [data, setData] = useState([]);
    const [employeeRecord, setEmployeeRecord] = React.useState([]);
    const [sheetName, setSheetName] = useState("");
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [designationFilter, setDesignationFilter] = useState('');

    useEffect(() => {
        // Fetch user data from MongoDB
        axios.get('https://br-attendance-backend.onrender.com/user').then((response) => {
            // Filter users by role
            setLoading(false)
            let adminData = response.data.getdata.filter((user) => user._id === id);
            setData(adminData[0]);
            const filteredUsers = response.data.getdata.filter((user) => user.role !== 'admin');
            setUsers(filteredUsers);
        });
    }, []);

    const getEmployeeData = (userId) => {

        axios
            .get(`https://br-attendance-backend.onrender.com/user/${userId}`)
            .then((response) => {

                const { attendance, empId, name, role, designation, leavesRemaining } =
                    response.data.getdatabyid;
                // console.log("excel", response, attendance)
                setSheetName(name + "-attendance-records");
                const formattedEmployeeRecord =
                    attendance &&
                    attendance?.map((obj) => ({
                        EmployeeId: empId,
                        Name: name,
                        Designation: designation,
                        Role: role,
                        Remaining_Leaves: leavesRemaining,
                        ...obj,
                    }));

                const initialRecord = [
                    {
                        EmployeeId: empId,
                        Name: name,
                        Designation: designation,
                        Role: role,
                        Remaining_Leaves: leavesRemaining,
                        checkIn: "",
                        checkOut: "",
                        Date: "",
                        Status: "",
                        Id: "1",
                    },
                ];




                const finalData =
                    formattedEmployeeRecord != []
                        ? formattedEmployeeRecord
                        : initialRecord;
                // console.log("finalExcel", finalData)
                setEmployeeRecord(finalData);

            })
            .catch((error) => {
                console.error("Failed to fetch employee data:", error);
            });
    };

    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="large"
                        style={{ flex: 'auto', marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="large" style={{ flex: 'auto' }}>
                        Reset
                    </Button>
                </div>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ fontSize: '18px', color: filtered ? '#1890ff' : '#666' }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => {
                    const inputElement = document.getElementById(`${dataIndex}-search-input`);
                    if (inputElement) {
                        inputElement.select();
                    }
                }, 100);
            }
        },
        render: (text) => {
            if (dataIndex === 'name') {
                return searchedColumn === dataIndex ? <span style={{ fontWeight: 'bold' }}>{text}</span> : text;
            }
            return text;
        },
    });


    const columns = [
        {
            title: 'Sl.No',
            key: 'serialNumber',
            render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
            width: 80,
            fixed: 'left',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name', 'Search by Name'),
            render: (text, record) => <Link to={`/admin/employeedashboard/${record._id}`}>{text}</Link>,
        },
        {
            title: 'Employee ID',
            dataIndex: 'empId',
            key: 'empId',
            ...getColumnSearchProps('empId', 'Search by Employee ID'),
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            // ...getColumnSearchProps('empId', 'Search by Employee ID'),
        },
        {
            title: 'Status',
            dataIndex: 'attendance',
            key: 'status',
            render: (attendance) => {
                const today = new Date().toISOString().slice(0, 10);
                const todayAttendance = attendance.find((record) => record.date === today);
                return todayAttendance ? todayAttendance.status : '';
            },
        },
        {
            title: 'Check-in',
            dataIndex: 'attendance',
            key: 'checkIn',
            render: (attendance) => {
                const today = new Date().toISOString().slice(0, 10);
                const todayAttendance = attendance.find((record) => record.date === today);
                return todayAttendance ? todayAttendance.checkIn : '';
            },
        },
        {
            title: 'Date',
            dataIndex: 'attendance',
            key: 'date',
            render: (attendance) => {
                const today = new Date().toISOString().slice(0, 10);
                const todayAttendance = attendance.find((record) => record.date === today);
                return todayAttendance ? todayAttendance.date.split('-').reverse().join('-') : '';
            },
        },
        {
            title: 'Leaves Rem.',
            dataIndex: 'leavesRemaining',
            key: 'leavesRemaining',
        },
        {
            title: 'Location',
            dataIndex: 'attendance',
            key: 'location',
            render: (attendance, record) => {
                const today = new Date().toISOString().slice(0, 10);
                const todayAttendance = attendance.find((record) => record.date === today);
                const lastLocation = todayAttendance ? todayAttendance : record.location.length ? record.location[record.location.length - 1] : {};
                // console.log("att", attendance, "rec", record,"tAdd", todayAttendance)
                return (
                    <>
                        Latitude: {lastLocation.lat}<br />
                        Longitude: {lastLocation.long}<br />
                        Place: {lastLocation.place}<br />
                        {/* Date: {lastLocation.date ? lastLocation.date.split('-').reverse().join('-') : ''}<br /> */}
                    </>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            render: (text, record) => (
                <Link to={`/admin/employee_details/${record._id}`}>
                    <Button type="primary" size="small">
                        View Monthly Data
                    </Button>
                </Link>
            ),
        },
        {
            title: "Download in Excel",

            render: (text, record) => (
                <Button type="" onClick={() => getEmployeeData(record._id)}>
                    <ExportCSV csvData={employeeRecord} fileName={sheetName} />
                </Button>
            ),
        }
    ];

    return (
        <>

            {/* <div id="mainCont" style={{ display: "flex", marginBottom:"5%" }}> */}
                <div className="col-md-6 col-xl-4 col-12">
                {/* <div id="left" style={{
                    width: "22%",
                }}>
                    <Sidebar />
                </div> */}
                {/* <div id="right" style={{
                    // marginLeft: "auto",
                    // marginTop: "5%",
                    // width:"78%"
                }}> */}
                     <div>
                        {/* <div style={{ width: "40%", margin: "auto" }}>
                    <EmpCard employee={data} />
                </div> */}
                        <Table
                            dataSource={users}
                            columns={columns}
                            rowKey="_id"
                            pagination={pagination}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            {/* </div> */}

        </>
    );
};

export default ListEmployee;


// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Input, Button, Table } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
// import Sidebar from '../components/Sidebar';
// import ExportCSV from '../components/ExportCSV';
// import { getUsers } from '../services/userService';

// const ListEmployeePage = () => {
//     const [users, setUsers] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [searchText, setSearchText] = useState('');
//     const [searchedColumn, setSearchedColumn] = useState('');
//     const [pagination, setPagination] = useState({
//         current: 1,
//         pageSize: 10,
//         total: 0,
//     });
//     const [employeeRecord, setEmployeeRecord] = useState([]);
//     const [sheetName, setSheetName] = useState('');

//     useEffect(() => {
//         fetchUsers();
//     }, []);

//     const fetchUsers = async (params = {}) => {
//         setLoading(true);
//         try {
//             const response = await getUsers(params);
//             const { data, total } = response;
//             setUsers(data);
//             setPagination((prevPagination) => ({
//                 ...prevPagination,
//                 total,
//             }));
//         } catch (error) {
//             console.log('Error fetching users:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleTableChange = (pagination) => {
//         const { current, pageSize } = pagination;
//         setPagination({ current, pageSize });
//         fetchUsers({ page: current, limit: pageSize });
//     };

//     const getEmployeeData = async (employeeId) => {
//         // Implementation for fetching employee data by ID
//         // Replace with your own implementation or remove if not needed
//     };

//     const handleFilter = (designationFilter, searchText) => {
//         // Implementation for filtering based on designation and search text
//         // Replace with your own implementation or remove if not needed
//     };

//     const getColumnSearchProps = (dataIndex, placeholder) => ({
//         filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//             <div style={{ padding: 8 }}>
//                 <Input
//                     ref={(node) => {
//                         searchInput = node; // Add this line to define searchInput
//                     }}
//                     placeholder={placeholder}
//                     value={selectedKeys[0]}
//                     onChange={(e) =>
//                         setSelectedKeys(e.target.value ? [e.target.value] : [])
//                     }
//                     onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                     style={{ width: 188, marginBottom: 8, display: 'block' }}
//                 />
//                 <Button
//                     type="primary"
//                     onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                     icon={<SearchOutlined />}
//                     size="small"
//                     style={{ width: 90, marginRight: 8 }}
//                 >
//                     Search
//                 </Button>
//                 <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
//                     Reset
//                 </Button>
//             </div>
//         ),
//         filterIcon: (filtered) => (
//             <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
//         ),
//         onFilter: (value, record) =>
//             record[dataIndex]
//                 ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
//                 : '',
//         onFilterDropdownVisibleChange: (visible) => {
//             if (visible) {
//                 setTimeout(() => searchInput.select()); // Add this line to define searchInput
//             }
//         },
//         render: (text) =>
//             searchedColumn === dataIndex ? (
//                 <Highlighter
//                     highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
//                     searchWords={[searchText]}
//                     autoEscape
//                     textToHighlight={text ? text.toString() : ''}
//                 />
//             ) : (
//                 text
//             ),
//     });

//     const handleSearch = (selectedKeys, confirm, dataIndex) => {
//         confirm();
//         setSearchText(selectedKeys[0]);
//         setSearchedColumn(dataIndex);
//     };

//     const handleReset = (clearFilters) => {
//         clearFilters();
//         setSearchText('');
//     };

//     const columns = [
//         // Existing columns...
//         // Add the new column with filter option
//         {
//             title: 'Designation',
//             dataIndex: 'designation',
//             key: 'designation',
//             ...getColumnSearchProps('designation', 'Search by Designation'),
//         },
//         // Existing columns...
//         // Rest of the columns
//     ];

//     return (
//         <div>
//             <Sidebar />
//             <div style={{ marginLeft: 300 }}>
//                 <h1 style={{ textAlign: 'center' }}>Employee List</h1>
//                 {/* Search input */}
//                 <div style={{ margin: '16px 0' }}>
//                     <Input
//                         placeholder="Search by Employee Id, Name, Designation, Role"
//                         onChange={(e) => {
//                             setSearchText(e.target.value);
//                             handleFilter(designationFilter, e.target.value);
//                         }}
//                         style={{ width: '40%', marginRight: '2%' }}
//                     />
//                     <Button type="primary" onClick={() => handleFilter(designationFilter, searchText)}>
//                         Search
//                     </Button>
//                     <Button onClick={() => handleFilter(designationFilter, '')} style={{ marginLeft: '2%' }}>
//                         Reset
//                     </Button>
//                 </div>
//                 {/* Table */}
//                 <Table
//                     columns={columns}
//                     dataSource={users}
//                     rowKey={(record) => record._id}
//                     pagination={pagination}
//                     loading={loading}
//                     onChange={handleTableChange}
//                 />
//                 {/* Export CSV button */}
//                 {employeeRecord.length > 0 && (
//                     <ExportCSV csvData={employeeRecord} fileName={sheetName} />
//                 )}
//             </div>
//         </div>
//     );
// };

// export default ListEmployeePage;

