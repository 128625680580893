import React from 'react'

export default function Footer() {
  return (
    <div>
          <footer class="pmd-footer">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-12 align-self-center">
                          <div class="pmd-site-info">
                              ©  2023<span class="auto-update-year"></span> a <a href="https://brandradiator.com/" target="_blank"><strong> Brand Radiator </strong></a> . All rights reserved.
                          </div>
                      </div>
                  </div>
              </div>
          </footer>
    </div>
  )
}
