import React from 'react'
import Navbar from '../components/Navbar';
import { Link, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import { useStatStyles } from '@chakra-ui/react';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
// import PageLoader from 'next/dist/client/page-loader';


export default function ApplyLeave({ adm }) {
    const [admin, setAdmin] = useState(false)
    const [data, setData] = useState([])
    let { id } = useParams();

    useEffect(() => {
        axios.get(`https://br-attendance-backend.onrender.com/user/${id}`).then((resp) => {
            console.log("resp", resp)
            setData(resp.data.getdatabyid)
            let { role } = resp.data.getdatabyid.role
            setAdmin(role === "admin" ? true : false)
        })

    }, [])

    const empPost = (e) => {
        e.preventDefault();
        // const leaveType = document.getElementById('leave-type').value;
        let startDate = document.getElementById('datepickerstart').value;
        let endDate = document.getElementById('datepickerend').value;
        let reason = document.getElementById('reason').value;

        let leavesArr = [];
        axios.get(`http://localhost:8000/leave/DRDLI0037`).then((resp) => {
            leavesArr.push(resp.data.leavedatabyid.leavesApplied)
            console.log("leaves resp:", resp, leavesArr)
        }).catch((err) => {
            console.log("l err:", err)
        })
        // Create the payload object
        leavesArr.push({
            startDate,
            endDate,
            reason
        })
        let payload = {
            empId: data.empId,
            leavesApplied: leavesArr
        }

        console.log("payload:p", payload)     
        // Send a POST request to the server
        axios.patch(`http://localhost:8000/leave/update/DRDLI0037`, payload)
            .then((response) => {
                // Handle the response here
                console.log('Post successful:', response.data);
                alert("Leave Applied!")
                // window.location ="http://localhost:3000/employeedashboard/64805b01fc624d86f3d5d1c5"

            })
            .catch((error) => {
                // Handle any errors here
                console.error('Error:', error);
            });
    }

    const adminPost = (e) => {
        e.preventDefault();
        const leaveType = document.getElementById('leave-type').value;
        const startDate = document.getElementById('datepickerstart').value;
        const endDate = document.getElementById('datepickerend').value;
        const reason = document.getElementById('reason').value;

        // Create the payload object
        const payload = {
            leaveType,
            startDate,
            endDate,
            reason
        };

        console.log(payload)
        // Send a POST request to the server
        // axios.post(`https://br-attendance-backend.onrender.com/user/${id}`, payload)
        //     .then((response) => {
        //         // Handle the response here
        //         console.log('Post successful:', response.data);
        //     })
        //     .catch((error) => {
        //         // Handle any errors here
        //         console.error('Error:', error);
        //     });
    }


    return (
        <div id='mainCont'
            style={{ display: "flex" }}
        >
            {admin ? <div style={{ width: "25%" }} id='sb'>
                <Sidebar />
            </div> : ""}
            <div style=
                {{
                    width: "75%",
                    margin: "auto",
                    marginTop: "10%",
                }} >
                <div id="content" class="pmd-content admin section-container">
                    {/* <!-- Title --> */}
                    <h1 >Apply Leave</h1>
                    {/* <!-- End Title --> */}

                    {/* <!--breadcrum start--> */}
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb pmd-breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to={admin ? `/dashboard` : `/employeedashboard/${id}`}>Dashboard</Link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Apply Leave</li>
                        </ol>
                    </nav>
                    {/* <!--breadcrum end--> */}

                    <div class="page-content">
                        {/* <!-- Apply Leave Form --> */}
                        <div class="card pmd-card single-col-form">
                            <form id="apply-leave" method="post" action="">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            {admin ? <div class="form-group pmd-textfield pmd-textfield-floating-label ">
                                                <label for="leave-type">Leave Type</label>
                                                <select name="leave-type" id="leave-type" class="form-control" title="Please select a Leave Type" required>
                                                    <option></option>
                                                    <option>Paid</option>
                                                    <option>Unpaid</option>

                                                </select>
                                            </div> : ""}
                                        </div>

                                        <div class="col-12">
                                            {admin ? <div class="form-group pmd-textfield pmd-textfield-floating-label ">
                                                <label for="leave-type">Leave Type</label>
                                                <select name="leave-type" id="leave-type" class="form-control" title="Please select a Leave Type" required>
                                                    <option></option>
                                                    <option>Sick Leave</option>
                                                    <option>Casual Leave</option>
                                                    <option>EBL</option>
                                                    <option>Unpaid Leave</option>
                                                    <option>Hourly Leave</option>
                                                </select>
                                            </div> : ""}
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group pmd-textfield pmd-textfield-floating-label">
                                                <label class="control-label" for="datepickerstart">Start Date</label>
                                                <input type="date" class="form-control" id="datepickerstart" name="datepickerstart" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group pmd-textfield pmd-textfield-floating-label">
                                                <label class="control-label" for="datepickerend">End Date</label>
                                                <input type="date" class="form-control" id="datepickerend" name="datepickerend" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group pmd-textfield pmd-textfield-floating-label">
                                                <label>Reason</label>
                                                <textarea class="form-control" id="reason" name="reason"></textarea>
                                            </div>
                                        </div>
                                        {/* <div class="col-12">
                                            <div class="custom-control custom-checkbox pmd-checkbox">
                                                <input class="custom-control-input" type="checkbox" value="" id="inverse_defaultCheck1" />
                                                <label class="custom-control-label" for="inverse_defaultCheck1">
                                                    Is it a Half Day?
                                                </label>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary pmd-ripple-effect pmd-btn-raised" name="applyleave" value="Apply Leave" onClick={admin ? adminPost : empPost}>Apply Leave</button> <Link to={admin ? `/dashboard` : `/employeedashboard/${id}`} class="btn btn-outline-secondary pmd-ripple-effect">Cancel!</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
