import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

export default function AboutPage() {
  return (
    <div>
         

              {/* <!-- Header Starts --> */}
             <Navbar />
              {/* <!-- Header Ends --> */}

              {/* <!-- Sidebar Starts --> */}
              <div class="pmd-sidebar-overlay"></div>

             

          <aside id="basicSidebar" class="pmd-sidebar pmd-sidebar-dark pmd-sidebar-slide-push pmd-sidebar-open bg-primary pmd-z-depth-light-2" role="navigation">
              <ul class="nav flex-column pmd-sidebar-nav">

                  {/* <!-- User info --> */}
                  <li class="nav-item pmd-user-info mb-0">
                      <a href="javascript:void(0);" class="nav-link btn-user text-center">
                          <img class="rounded-circle m-auto" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPiccyyH4GrWG-DzBCAaRQ9ZFhxnfEe3k4yw&usqp=CAU" width="40" height="40" alt="avatar" />
                          <h3 class="pmd-title m-0">BR Admin</h3>
                          <p class="pmd-subheading1 m-0">info@brandradiator.com</p>
                      </a>
                  </li>
                  {/* <!-- End user info --> */}

                  <li class="nav-item">
                      {/* <a class="nav-link pmd-ripple-effect" href="dashboard.html"> */}
                      <Link to='/' class="nav-link pmd-ripple-effect" >
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              dashboard
                          </i>
                          <span class="media-body">Dashboard</span>
                      </Link>
                  </li>

                  <li class="nav-item">
                      <Link to='/employeelist' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Employees</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li>

                  <li class="nav-item">
                      <Link to='/addemployeepage' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Add Employees</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li>

                  {/* <li class="nav-item">
                      <Link to='employeelist' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Employee's Detail </span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li> */}

                  {/* <ul class="collapse" id="employee-menu" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="employee-list-grid-view.html">Employee List</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-list-with-datatable.html">Employee List with Data Table</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="add-employee.html">Add Employee</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link pmd-ripple-effect" href="employee-detail.html">Employee Detail</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-blank-state.html">Employee Blank State</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-search-no-result-found.html">Employee Search No Result Found</a>
                          </li>
                      </ul> */}


                  {/* <li class="nav-item">
                       <a class="nav-link pmd-ripple-effect" href="interview.html"> 
                      <a class="nav-link pmd-ripple-effect" href="#">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              dashboard
                          </i>
                          <span class="media-body">Interview</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/leavepage" class="nav-link pmd-ripple-effect" >
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              date_range
                          </i>
                          <span class="media-body">Leave</span>
                      </Link>
                  </li>

                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="inbox.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              inbox
                          </i>
                          <span class="media-body">Inbox</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/eventcalendar" class="nav-link pmd-ripple-effect" >
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              today
                          </i>
                          <span class="media-body">Event Calendar</span>
                      </Link>
                  </li>

                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="notifications.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              notifications
                          </i>
                          <span class="media-body">Notifications</span>
                      </a>
                  </li> */}

                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="email-templates.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              web
                          </i>
                          <span class="media-body">Email Templates</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/login" class="nav-link" data-toggle="collapse">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              exit_to_app
                          </i>
                          <span class="media-body">Login</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                      {/* <ul class="collapse" id="login-menu" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="login.html">Login Option 1</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="login2.html">Login Option 2</a>
                          </li>
                      </ul> */}
                  </li>

                  {/* <li class="nav-item">
                      <a class="nav-link" href="contact.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              call
                          </i>
                          <span class="media-body">Contact</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/aboutpage" class="nav-link" data-toggle="collapse" >
                          <i class="material-icons pmd-list-icon pmd-sm">
                              pages
                          </i>
                          <span class="media-body">About</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                      {/* <ul class="collapse" id="thirdpartyelements" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="about.html">About</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="alert.html">Alerts and Warnings</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="404.html">404</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="404-with-sidebar.html">404 with Sidebar</a>
                          </li>
                      </ul> */}
                  </li>
                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="account-settings.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              settings
                          </i>
                          <span class="media-body">Account Settings</span>
                      </a>
                  </li> */}
                  <li class="nav-item">
                      <a class="nav-link" href="login.html">
                          <i class="material-icons pmd-list-icon pmd-sm">power_settings_new</i>
                          <span class="media-body">Logout</span>
                      </a>
                  </li>
              </ul>
          </aside>
         
              <div id="content" class="pmd-content section-container admin">

                  {/* <!-- Title --> */}
                  <h1 >About</h1>
                
                  <nav aria-label="breadcrumb">
                      <ol class="breadcrumb pmd-breadcrumb">
                          <li class="breadcrumb-item">
                              <a href="dashboard.html">Dashboard</a>
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">About</li>
                      </ol>
                  </nav>
                  
                  <div class="card pmd-card">
                      <div class="card-body">
                          <h2 >Develop more, Code less.</h2>
                          <p>Welcome to Propeller. Propeller is a front-end responsive framework based on Google's Material Design Standards & Bootstrap.</p>
                          <p>Material design is a visual language for our users that synthesizes the classic principles of good design with the and possibility of technology and science.</p>
                          <p>Bootstrap is the most popular HTML, CSS, and JS framework in the world for building responsive, mobile-first projects on the web.</p>
                          <p>The combination of Material design and Bootstrap that makes your website more attractive, consistent, and functionally powerful and that is what Propeller is all about.</p>
                          <h2 >Features of Propeller</h2>
                          <ol>
                              <li>Responsive Component Library</li>
                              <li>Speed up Development</li>
                              <li>Detailed User Manual</li>
                          </ol>
                          <h2 >How it works?</h2>
                          <p>Propeller comes in three different forms. You can choose either of them depending on your preference and requirements. Download from one of the options available below to get started.</p>
                          <div>
                              <h3>Individual Components</h3>
                              <p>Provides Individual component with complete and accurate information described. Download and use the individual component. You'll get the css, javascript and html files of the component.</p>
                          </div>
                          <div>
                              <h3>Download Entire Kit</h3>
                              <p>Complete collection of Propeller UI components kit to download and use. This kit has both minified and unminified versions of css and javascript along with html code for all 22 Components.</p>
                          </div>
                          <div>
                              <h3>Reuse our Templates</h3>
                              <p>Use our precreated templates with all standards to save your time. Download this to themify your bootstap based html page. You'll have to include the css and js files in your html page.</p>
                          </div>
                      </div>
                  </div>
                  {/* <!-- section content end --> */}

              </div>
              {/* <!-- content area end -->

              <!-- Footer Starts --> */}
              <Footer />
            

             

       
    </div>
  )
}


 {/*<!-- build:include snippets/js-files-404.html -->
              <!-- /build -->

              <!-- build:remove -->
              <!--Admin js-->
              <script type="text/javascript" src="themes/js/bootstrap4-admin-compress.min.js"></script>
              <!-- /build --> */}

          {/* <script>
                  $(document).ready(function() {
        var sPath=window.location.pathname;
                  var sPage = sPath.substring(sPath.lastIndexOf('/') + 1);
                  $(".pmd-sidebar-nav").each(function(){
                      $(this).find("a[href='" + sPage + "']").parents(".collapse").addClass("show");
                  $(this).find("a[href='"+sPage+"']").parents(".collapse").prev('a.nav-link').addClass("active");
                  $(this).find("a[href='"+sPage+"']").addClass("active");
        });
                  $(".auto-update-year").html(new Date().getFullYear());
    });
              </script> */}
          {/* <!-- Scripts Ends --> */}