
import { useState } from 'react';
import './App.css';
import Footer from './components/Footer';
import ListView from './components/ListView';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import AboutPage from './pages/AboutPage';
import AddEmployee from './pages/AddEmployee';
import ApplyLeave from './pages/ApplyLeave';
import ApplyLeaveforEmp from './pages/ApplyLeaveforEmp';
import EmpAddLeave from './pages/EmpAddLeave';
import EmployeeDashboard from './pages/EmployeeDashboard';
import EmployeeList from './pages/EmployeeList';
import EventCalendar from './pages/EventCalendar';
import Home from './pages/Home';
import Leave from './pages/Leave';
import ListEmployee from './pages/ListEmployeePage';
import Login from './pages/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  const [login,setLogin]=useState(false);
  const [empid,setEmpid]=useState("");
  const [navAdmin, setNavAdmin]= useState(false)
  const [showSide, setShowSide] = useState(false)

  const idFun=(x)=>{
    console.log("empiddddddd",empid)
    setEmpid(x)
  }
  
  const setLoginFunc = (x) =>{
    console.log(login,"login")
    setLogin(x)
  }
  
  const navAdminFun = (x) =>{
    setNavAdmin(x)
    console.log(navAdmin)
  }

  const showSideFun = () =>{
    setShowSide(!showSide)
  }
  
  return (
    <div className="App">
      {/* <Home /> */}
      {/* <Login /> */}
      <BrowserRouter>
        <div className='conatiner'>
          <Navbar showSide={showSide} sideFun={showSideFun} empid={empid} admin={navAdmin} login={login}/>
          <div id="cont"
          style={{
            // display:"flex"
          }}>
            {/* <div id='sbar'>
              <Sidebar />
            </div> */}
            <div id='comps'>
              <Routes>
                <Route path='/dashboard' element={<Home />} />
                <Route path='/' element={<Login idFun={idFun} loginF={setLoginFunc} admin={true} navF={navAdminFun} />} />
                <Route path='/employeelist' element={<EmployeeList />} />
                <Route path='/eventcalendar' element={<EventCalendar />} />
                <Route path='/leavepage' element={<Leave />} />
                <Route path='/aboutpage' element={<AboutPage />} />
                <Route path='/addemployeepage' element={<AddEmployee />} />
                <Route path='/addleave' element={<ApplyLeave />} />
                {/* <Route path='/admin-login' element={<Login    />} /> */}
                {/* <Route path='/side' element={<Sidebar />} /> */}
                <Route path='/employeedashboard/:id' element={<EmployeeDashboard admin={false} />}></Route>
                <Route path='/admin/employeedashboard/:id' element={<EmployeeDashboard admin={true} />}></Route>
                {/* <Route path='/list' element={<ListEmployee />} /> */}
                {/* <Route path='/listView' element={<ListView />} /> */}
                <Route  path='/addleave/:id' element={<ApplyLeave adm={false} />} />
                <Route path='/admin/addleave' element={<ApplyLeave adm={true} />} />
              </Routes>
            </div>
          </div>
          

        </div>
        <div
          style=
          {{
            // marginLeft: "10%"
          }}
        ><br></br><Footer /></div>

      </BrowserRouter>




    </div>
  );
}

export default App;
