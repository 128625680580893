import React from 'react'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div>
        
    <div class="pmd-sidebar-overlay"></div>

   {/* <Sidebar /> */}
          <div id='mainCont'
              style={{ display: "flex" }}
          >
              <div style={{ width: "25%" }} id='sb'>
                  <Sidebar />
              </div>
              <div style=
                  {{
                      width: "75%",
                      margin: "auto",
                      marginTop: "10%",
                  }} >
                  <div class="row">
                      {/* <!-- Department Wise Head Count--> */}
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                          <div class="card pmd-card sms-details">
                              <div class="card-header pmd-card-border">
                                  <h2 class="card-title h3">Monthly Attendance Report</h2>
                                  <p class="card-subtitle">Total no of employess in each department</p>
                              </div>
                              <div class="card-body">
                                  {/* <!--chart start--> */}
                                  <div class="chart-container">
                                      <canvas id="bar-chartcanvas" height="350"></canvas>
                                  </div>
                                  {/* <!--chart end--> */}
                              </div>
                          </div>
                      </div>
                      {/* <!-- end User Details--> */}

                      {/* <!-- Today's Presence --> */}
                      <div class="col-12 col-sm-6 col-lg-6 col-xl-4">
                          <div class="card pmd-card sms-details">
                              <div class="card-header pmd-card-border">
                                  <h2 class="card-title h3">Today's Attendance</h2>
                                  <p class="card-subtitle">The total no of present and absent employees.</p>
                              </div>
                              <div class="card-body">
                                  {/* <!--chart start--> */}
                                  <div class="doughnut-chart-container">
                                      <canvas id="doughnut-chartcanvas" width="300" height="300"></canvas>
                                  </div>
                                  {/* <!--chart end--> */}
                              </div>
                          </div>
                      </div>
                      
                      <div class="col-12">
                          {/* <!-- Card Component --> */}
                          <div class="card pmd-card">
                              {/* <!-- Card Header --> */}
                              <div class="card-header pmd-card-border d-flex align-items-start">
                                  <div class="media-body">
                                      <h2 class="card-title h3">Leave Applications</h2>
                                      <p class="card-subtitle">Application of Leaves by Employees</p>
                                  </div>
                                  <a class="btn pmd-ripple-effect btn-outline-primary ml-auto btn-sm" href="leave.html">View All</a>
                              </div>
                              {/* <!-- Card Header End --> */}

                              {/* <!-- Card Body --> */}
                              <div class="card-body">
                                  <div class="body">
                                      <div class="table-responsive">
                                          <table class="table pmd-table table-hover">
                                              <thead>
                                                  <tr>
                                                      <th>Employee</th>
                                                      <th>Leave Type</th>
                                                      <th>Half Day</th>
                                                      <th>Start Date</th>
                                                      <th>End Date</th>
                                                      <th>Reason</th>
                                                      <th>No of Leave</th>
                                                      <th>Paid / Unpaid</th>
                                                      <th>Status</th>
                                                      <th></th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>Tarah L. Faulkner</td>
                                                      <td>Casual Leave</td>
                                                      <td>No</td>
                                                      <td>1<sup>st</sup> September, 2018</td>
                                                      <td>3<sup>rd</sup> September, 2018</td>
                                                      <td>Attend Cousin's Wedding</td>
                                                      <td>3</td>
                                                      <td>Paid</td>
                                                      <td>Pending</td>
                                                      <td>
                                                          <a href="javascript:void(0);" class="pmd-btn-fab btn-xs btn-outline-secondary pmd-ripple-effect btn mr-2">
                                                              <i class="material-icons">done</i>
                                                          </a>
                                                          <a href="javascript:void(0);" title="Reject" class="pmd-btn-fab btn-xs btn-outline-danger pmd-ripple-effect btn" data-toggle="modal" data-target="#reject-modal">
                                                              <i class="material-icons">close</i>
                                                          </a>
                                                      </td>
                                                  </tr>
                                                  <tr>
                                                      <td>Julia Warne</td>
                                                      <td>Sick Leave</td>
                                                      <td>Yes</td>
                                                      <td>1<sup>st</sup> December, 2018</td>
                                                      <td>1<sup>st</sup> December, 2018</td>
                                                      <td>Not feeling well</td>
                                                      <td>0.5</td>
                                                      <td>Paid</td>
                                                      <td>Pending</td>
                                                      <td>
                                                          <a href="javascript:void(0);" class="pmd-btn-fab btn-xs btn-outline-secondary btn mr-2 pmd-ripple-effect">
                                                              <i class="material-icons">done</i>
                                                          </a>
                                                          <a href="javascript:void(0);" title="Reject" class="pmd-btn-fab btn-xs btn-outline-danger btn pmd-ripple-effect" data-toggle="modal" data-target="#reject-modal">
                                                              <i class="material-icons">close</i>
                                                          </a>
                                                      </td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                              {/* <!-- Card Body End --> */}
                          </div>
                          {/* <!-- Card Component End --> */}
                      </div>
                      {/* <!-- Leave Application Block End --> */}
                  </div>
              </div>

          </div>

    <div id="content" class="pmd-content content-area dashboard">
        {/* <div class="row">
                  <div class="col-sm-6 col-lg-6 col-xl-3">
                <div class="card pmd-card statistic-col new-users-statistic">
                    <div class="card-body media">
                        <i class="pmd-icon-circle pmd-icon-xl md-light">
                      
                    </i>
                        <div class="media-body">
                            <h2 class="card-title display-3">32</h2>
                            <p class="card-subtitle">Employees</p>
                        </div>
                    </div>
                </div>
            </div>
                  <div class="col-sm-6 col-lg-6 col-xl-3">
                <div class="card pmd-card statistic-col download-statistic">
                    <div class="card-body media">
                        <i class="pmd-icon-circle pmd-icon-xl md-light">
                      
                    </i>
                        <div class="media-body">
                            <h2 class="card-title display-3">0</h2>
                            <p class="card-subtitle">Open Vacancies</p>
                        </div>
                    </div>
                </div>
            </div>

            
                  <div class="col-sm-6 col-lg-6 col-xl-3">
                <div class="card pmd-card statistic-col visits-statistic">
                    <div class="card-body media">
                        <i class="material-icons pmd-icon-circle pmd-icon-xl md-light">
               
                    </i>
                        <div class="media-body">
                            <h2 class="card-title display-3">10,000</h2>
                            <p class="card-subtitle">Total Payable Salary</p>
                        </div>
                    </div>
                </div>
            </div>

                  <div class="col-sm-6 col-lg-6 col-xl-3">
                <div class="card pmd-card happy-users-statistic">
                    <div class="card-body media align-items-center">
                        <p class="card-title">Payroll <br/> Status</p>
                        <div class="canvas media-body">
                            <div class="circle mr-3">
                                <div id="circles-1">
                                </div>
                                <div class="circle-text">Done</div>
                            </div>
                            <div class="circle">
                                <div id="circles-2"></div>
                                <div class="circle-text">Pending</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

       
    </div>
    {/* <!--end content area--> */}

    {/* <!-- Footer Starts --> */}
   <Footer />
    {/* <!-- Footer Ends --> */}

    {/* <!-- Reject Leave Request Modal --> */}
    <div class="modal pmd-modal fade text-center delete-modal" id="reject-modal" tabindex="-1" role="dialog" aria-labelledby="reject-modal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <i class="material-icons md-danger pmd-icon-circle bg-danger pmd-icon-md"><strong>error_outline</strong></i>
                </div>
                {/* <!-- Modal Body --> */}
                <div class="modal-body">
                    <h2 class="modal-title">Are you sure?</h2>
                    <p>Do you really want to reject the employee's leave? <br/> This process cannot be undone.</p>
                    <a href="javascript:void(0);" class="btn btn-outline-dark pmd-btn-raised pmd-ripple-effect" title="Cancel" data-dismiss="modal">Cancel</a>
                    <a href="javascript:void(0);" class="btn btn-primary pmd-btn-raised pmd-ripple-effect" title="Delete">Reject</a>
                </div>
            </div>
        </div>
    </div>

    {/* <!-- build:include snippets/js-files-index.html -->
    <!-- /build -->

    <!-- build:remove -->
    <!--Admin js--> */}
    <script type="text/javascript" src="assets/js/bootstrap4-admin-compress.min.js"></script>
    {/* <!-- /build --> */}






    </div>
  )
}

export default Home