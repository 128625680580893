import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import AddEmployeeForm from '../components/AddEmployeeForm';
import Sidebar from '../components/Sidebar';


export default function AddEmployee() {
    return (
        <div id='mainCont'
            style={{ display: "flex" }}
        >
            <div style={{ width: "25%" }} id='sb'>
                <Sidebar />
            </div>
            <div style=
                {{
                    width: "75%",
                    margin: "auto",
                    marginTop: "10%",
                }} id='aef'>
                <AddEmployeeForm />
            </div>

        </div>
    )
}

