import React from 'react'
import { Skeleton } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';

export default function SkeletonComp() {
    return (
        <div>
            <div id='skeleton'>
                <div className="sk-top"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        //   border: "1px solid red"
                    }}

                >
                    <Skeleton style={{ width: "20%" }}
                        active avatar round
                    />

                    <SkeletonButton
                        style={{ marginTop: "100%" }}
                        active
                    />
                </div>
                <br />
                <br />
                .              <div className="sk-bottom">
                    <Skeleton size='large' title={false} active paragraph={true} /> <br />
                    <Skeleton title={false} active /><br />
                    <Skeleton title={false} active /><br />
                    <Skeleton title={false} active /><br />
                    <Skeleton title={false} active /><br />
                    <Skeleton title={false} active /><br />
                </div>
            </div>
        </div>
    )
}
