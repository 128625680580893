import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const EmployeeCards = ({userData}) => {
    console.log("card", userData)
    // const [userData, setUserData] = useState(null);

    // useEffect(() => {
    //     // Fetch user data from the specified URL
    //     axios.get('https://br-attendance-backend.onrender.com/user').then((response) => {
    //         // Assuming you want to use the first user's data from the response
    //         if (response.data.getdata.length > 0) {
    //             setUserData(response.data.getdata[0]);
    //         }
    //     });
    // }, []);

    return (
        <div className="col-md-6 col-xl-4 col-12">
            {/* Card Component */}
            <div className="card pmd-card user-info-card">
                {/* Card Header */}
                <div className="card-header pmd-card-border d-flex flex-row align-items-center">
                    {/* <a className="pmd-avatar-list-img" href="javascript:void(0);"> */}
                        <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPiccyyH4GrWG-DzBCAaRQ9ZFhxnfEe3k4yw&usqp=CAU"} width="40" height="40" alt="Profile Image" />
                    {/* </a> */}
                    <div className="media-body">
                        <h3 className="card-title">{userData?.name}</h3>
                        <p className="card-subtitle">{userData?.designation}</p>
                    </div>
                    <div className="dropdown pmd-dropdown pmd-user-info ml-auto">
                        <a href="javascript:void(0);" className="pmd-btn-fab btn-dark pmd-btn-flat btn btn-sm" data-toggle="dropdown" aria-expanded="true">
                            <i className="material-icons pmd-icon-sm">more_vert</i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            {/* <a className="dropdown-item d-flex flex-row align-items-center" href="employee-detail.html">
                                <i className="material-icons md-dark pmd-icon-sm mr-3">edit</i>
                                <span className="media-body">Edit</span>
                            </a> */}
                            <a href="javascript:void(0);" title="Delete" className="dropdown-item d-flex flex-row align-items-center" data-toggle="modal" data-target="#delete_employee_modal">
                                <i className="material-icons md-dark pmd-icon-sm mr-3">delete</i>
                                <span className="media-body">Delete</span>
                            </a>
                        </div>

                    </div>
                </div>

                <ul className="list-group pmd-list">
                    <li className="list-group-item d-flex flex-row">
                        <i className="material-icons pmd-list-icon align-self-center pmd-md md-dark">person_outline</i>
                        <div className="media-body">
                            <label className="pmd-list-subtitle">Employee ID</label>
                            <p className="pmd-list-title">{userData?.empId}</p>
                        </div>
                    </li>
                    {/* <li className="list-group-item d-flex flex-row">
                        <i className="material-icons pmd-list-icon align-self-center pmd-md md-dark">call</i>
                        <div className="media-body">
                            <label className="pmd-list-subtitle mb-0">Phone No.</label>
                            <p className="pmd-list-title">{userData?.phone}</p>
                        </div>
                    </li> */}
                    {/* <li className="list-group-item d-flex flex-row">
                        <i className="material-icons pmd-list-icon align-self-center pmd-md md-dark">mail_outline</i>
                        <div className="media-body">
                            <label className="pmd-list-subtitle">Email Address</label>
                            <p className="pmd-list-title">
                                <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
                            </p>
                        </div>
                    </li> */}
                    {/* <li className="list-group-item d-flex flex-row">
                        <i className="material-icons pmd-list-icon align-self-center pmd-md md-dark">people_outline</i>
                        <div className="media-body">
                            <label className="pmd-list-subtitle">Department</label>
                            <p className="pmd-list-title">{userData?.department}</p>
                        </div>
                    </li> */}
                    {/* <li className="list-group-item d-flex flex-row">
                        <i className="material-icons pmd-list-icon align-self-center pmd-md md-dark">today</i>
                        <div className="media-body">
                            <label className="pmd-list-subtitle">Date of Joining</label>
                            <p className="pmd-list-title">{userData?.dateOfJoining}</p>
                        </div>
                    </li> */}
                </ul>

                <div className="card-footer pmd-card-border">
                    {/* <a title="View More" href="employee-detail.html" className="btn pmd-btn-flat pmd-ripple-effect btn-primary"> */}
                    <Link title="View More"  className="btn pmd-btn-flat pmd-ripple-effect btn-primary" to={`/admin/employeedashboard/${userData._id}`}>View Employee Dashboard</Link> 
                    {/* </a> */}
                </div>
            </div>
        </div>
    );
};

export default EmployeeCards;
