import React from 'react'
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom'; 
import Footer from '../components/Footer';

export default function EventCalendar() {
  return (
    <div>
        

             
              <Navbar />
              {/* <!-- Header Ends --> */}

              {/* <!-- Sidebar Starts --> */}
              <div class="pmd-sidebar-overlay"></div>

              {/* <!-- Left sidebar --> */}
             {/* <Sidebar /> */}
           
          <aside id="basicSidebar" class="pmd-sidebar pmd-sidebar-dark pmd-sidebar-slide-push pmd-sidebar-open bg-primary pmd-z-depth-light-2" role="navigation">
              <ul class="nav flex-column pmd-sidebar-nav">

                  {/* <!-- User info --> */}
                  <li class="nav-item pmd-user-info mb-0">
                      <a href="javascript:void(0);" class="nav-link btn-user text-center">
                          <img class="rounded-circle m-auto" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPiccyyH4GrWG-DzBCAaRQ9ZFhxnfEe3k4yw&usqp=CAU" width="40" height="40" alt="avatar" />
                          <h3 class="pmd-title m-0">BR Admin</h3>
                          <p class="pmd-subheading1 m-0">info@brandradiator.com</p>
                      </a>
                  </li>
                  {/* <!-- End user info --> */}

                  <li class="nav-item">
                      {/* <a class="nav-link pmd-ripple-effect" href="dashboard.html"> */}
                      <Link to='/' class="nav-link pmd-ripple-effect" >
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              dashboard
                          </i>
                          <span class="media-body">Dashboard</span>
                      </Link>
                  </li>

                  <li class="nav-item">
                      <Link to='/employeelist' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Employees</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li>

                  <li class="nav-item">
                      <Link to='/addemployeepage' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Add Employees</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li>

                  {/* <li class="nav-item">
                      <Link to='employeelist' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Employee's Detail </span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li> */}

                  {/* <ul class="collapse" id="employee-menu" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="employee-list-grid-view.html">Employee List</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-list-with-datatable.html">Employee List with Data Table</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="add-employee.html">Add Employee</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link pmd-ripple-effect" href="employee-detail.html">Employee Detail</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-blank-state.html">Employee Blank State</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-search-no-result-found.html">Employee Search No Result Found</a>
                          </li>
                      </ul> */}


                  {/* <li class="nav-item">
                       <a class="nav-link pmd-ripple-effect" href="interview.html"> 
                      <a class="nav-link pmd-ripple-effect" href="#">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              dashboard
                          </i>
                          <span class="media-body">Interview</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/leavepage" class="nav-link pmd-ripple-effect" >
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              date_range
                          </i>
                          <span class="media-body">Leave</span>
                      </Link>
                  </li>

                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="inbox.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              inbox
                          </i>
                          <span class="media-body">Inbox</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/eventcalendar" class="nav-link pmd-ripple-effect" >
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              today
                          </i>
                          <span class="media-body">Event Calendar</span>
                      </Link>
                  </li>

                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="notifications.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              notifications
                          </i>
                          <span class="media-body">Notifications</span>
                      </a>
                  </li> */}

                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="email-templates.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              web
                          </i>
                          <span class="media-body">Email Templates</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/login" class="nav-link" data-toggle="collapse">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              exit_to_app
                          </i>
                          <span class="media-body">Login</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                      {/* <ul class="collapse" id="login-menu" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="login.html">Login Option 1</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="login2.html">Login Option 2</a>
                          </li>
                      </ul> */}
                  </li>

                  {/* <li class="nav-item">
                      <a class="nav-link" href="contact.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              call
                          </i>
                          <span class="media-body">Contact</span>
                      </a>
                  </li> */}

                  <li class="nav-item">
                      <Link to="/aboutpage" class="nav-link" data-toggle="collapse" >
                          <i class="material-icons pmd-list-icon pmd-sm">
                              pages
                          </i>
                          <span class="media-body">About</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                      {/* <ul class="collapse" id="thirdpartyelements" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="about.html">About</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="alert.html">Alerts and Warnings</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="404.html">404</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="404-with-sidebar.html">404 with Sidebar</a>
                          </li>
                      </ul> */}
                  </li>
                  {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="account-settings.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              settings
                          </i>
                          <span class="media-body">Account Settings</span>
                      </a>
                  </li> */}
                  <li class="nav-item">
                      <a class="nav-link" href="login.html">
                          <i class="material-icons pmd-list-icon pmd-sm">power_settings_new</i>
                          <span class="media-body">Logout</span>
                      </a>
                  </li>
              </ul>
          </aside>


              {/* <!--content area start--> */}
              <div id="content" class="pmd-content inner-page admin">
                  <div class="calendar-cover">
                      <div class="calendar-cover-content">
                          {/* <!--section-title start --> */}
                          <h1 id="contact-title">Event Calendar</h1>
                          {/* <!--section-title end --> */}

                          {/* <!--breadcrum start--> */}
                          <nav aria-label="breadcrumb">
                              <ol class="breadcrumb pmd-breadcrumb">
                                  <li class="breadcrumb-item">
                                      <a href="dashboard.html">Dashboard</a>
                                  </li>
                                  <li class="breadcrumb-item active" aria-current="page">Calendar</li>
                              </ol>
                          </nav>
                          {/* <!--breadcrum end--> */}
                      </div>
                  </div>

                  {/* <!-- section content start--> */}
                  <section class="component-section">
                      <div id='calendar' class="pmd-calendar"></div>
                  </section>
                  {/* <!-- section content end --> */}

              </div>
              {/* <!-- content area end --> */}

              {/* <!-- Footer Starts --> */}
             <Footer />
              {/* <!-- Footer Ends -->

              <!-- Form Modal --> */}
              <div tabindex="-1" class="modal pmd-modal fade add-dialog" id="createEventModal" style={{display: "none;"}} aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                          <div class="modal-header pmd-modal-border">
                              <h2 class="modal-title">Create Event</h2>
                              <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
                          </div>

                          <div class="modal-body">
                              <form id="createAppointmentForm">
                                  <div class="form-group pmd-textfield pmd-textfield-floating-label">
                                      <label for="mobil">Event Name</label>
                                      <input type="text" class="form-control" name="patientName" id="patientName" tyle="margin: 0 auto;" data-provide="typeahead" data-items="4" data-source="[&quot;Value 1&quot;,&quot;Value 2&quot;,&quot;Value 3&quot;]"/>
                                          <input type="hidden" class="form-control" id="apptStartTime" />
                                          <input type="hidden" class="form-control" id="apptEndTime" />
                                          <input type="hidden" class="form-control" id="apptAllDay" />
                                  </div>
                                  <div class="form-group pmd-textfield">
                                      <label class="control-label" for="when">When:</label>
                                      <div class="controls controls-row" id="when" style={{marginTop:"5px"}}></div>
                                  </div>
                              </form>
                          </div>
                          <div class="modal-footer">
                              <button data-dismiss="modal" id="submitButton" class="btn pmd-ripple-effect btn-primary pmd-btn-raised" type="button">Send</button>
                              <button data-dismiss="modal" class="btn pmd-ripple-effect btn-outline-dark" type="button">Reset</button>
                          </div>
                      </div>
                  </div>
              </div>

              {/* <!-- build:include snippets/js-files-calendar.html -->
              <!-- /build -->

              <!-- build:remove -->
              <!--Admin js--> */}
              {/* <script type="text/javascript" src="themes/js/bootstrap4-admin-compress.min.js"></script> */}
              {/* <!-- /build --> */}

              {/* <script>
                  $(document).ready(function() {
		var sPath=window.location.pathname;
                  var sPage = sPath.substring(sPath.lastIndexOf('/') + 1);
                  $(".pmd-sidebar-nav").each(function(){
                      $(this).find("a[href='" + sPage + "']").parents(".collapse").addClass("show");
                  $(this).find("a[href='"+sPage+"']").parents(".collapse").prev('a.nav-link').addClass("active");
                  $(this).find("a[href='"+sPage+"']").addClass("active");
		});
                  $(".auto-update-year").html(new Date().getFullYear());
	});
              </script> */}
              {/* <script>

                  $(document).ready(function() {

                      initThemeChooser({

                          init: function (themeSystem) {
                              $('#calendar').fullCalendar({
                                  themeSystem: 'bootstrap4',
                                  header: {
                                      left: 'today',
                                      center: 'prev,title,next',
                                      right: 'month,agendaWeek,agendaDay,listMonth'
                                  },
                                  defaultView: isMobileDevice() ? "agendaDay" : "month",
                                  viewRender: function (view, element) {
                                      //	element.find('table.table-bordered').addClass('pmd-table table');
                                  },
                                  weekNumbers: false,
                                  navLinks: true, // can click day/week names to navigate views
                                  editable: true,
                                  eventLimit: true, // allow "more" link when too many events
                                  selectable: true,
                                  selectHelper: true,
                                  height: 900,
                                  select: function (start, end, allDay) {
                                      endtime = $.fullCalendar.formatDate(end, 'h:mm tt');
                                      starttime = $.fullCalendar.formatDate(start, 'ddd, MMM d, h:mm tt');
                                      var mywhen = starttime + ' - ' + endtime;
                                      $('#createEventModal #apptStartTime').val(start);
                                      $('#createEventModal #apptEndTime').val(end);
                                      $('#createEventModal #apptAllDay').val(allDay);
                                      $('#createEventModal #when').text(mywhen);
                                      $('#createEventModal').modal('show');
                                  },
                                  events: [
                                      {
                                          title: 'All Day Event',
                                          start: '2018-07-01',
                                          backgroundColor: '#000',
                                          borderColor: '#000'
                                      },
                                      {
                                          title: 'Long Event',
                                          start: '2018-07-07',
                                          end: '2018-07-10',
                                          backgroundColor: '#edfcf9',
                                          textColor: '#33b795',
                                          borderColor: '#edfcf9'
                                      },
                                      {
                                          id: 999,
                                          title: 'Repeating Event',
                                          start: '2018-07-09T16:00:00',
                                          backgroundColor: '#fff9e9',
                                          textColor: '#d29026',
                                          borderColor: '#fff9e9'
                                      },
                                      {
                                          id: 999,
                                          title: 'Repeating Event',
                                          start: '2018-07-16T16:00:00',
                                          backgroundColor: '#ffebec',
                                          textColor: '#df7172',
                                          borderColor: '#ffebec'
                                      },
                                      {
                                          title: 'Conference',
                                          start: '2018-07-11',
                                          end: '2018-07-13',
                                          backgroundColor: '#f4f4ff',
                                          textColor: '#7474cb',
                                          borderColor: '#f4f4ff'
                                      },
                                      {
                                          title: 'Meeting',
                                          start: '2018-07-12T14:30:00',
                                          backgroundColor: '#edfcf9',
                                          textColor: '#33b795',
                                          borderColor: '#edfcf9'
                                      },
                                      {
                                          title: 'Happy Hour',
                                          start: '2018-07-12T17:30:00',
                                          backgroundColor: '#fff9e9',
                                          textColor: '#d29026',
                                          borderColor: '#fff9e9'
                                      },
                                      {
                                          title: 'Dinner',
                                          start: '2018-07-12T20:00:00',
                                          backgroundColor: '#000',
                                          borderColor: '#000'
                                      },
                                      {
                                          title: 'Birthday Party',
                                          start: '2018-07-13T07:00:00',
                                          backgroundColor: '#edfcf9',
                                          textColor: '#33b795',
                                          borderColor: '#edfcf9'
                                      },
                                  ]
                              });

                              $('#submitButton').on('click', function (e) {
                                  e.preventDefault();
                                  doSubmit();
                              });

                              function doSubmit() {
                                  $("#createEventModal").modal('hide');
                                  console.log($('#apptStartTime').val());
                                  console.log($('#apptEndTime').val());
                                  console.log($('#apptAllDay').val());
                                  $("#calendar").fullCalendar('renderEvent', {
                                      title: $('#patientName').val(),
                                      start: new Date($('#apptStartTime').val()),
                                      end: new Date($('#apptEndTime').val()),
                                      allDay: ($('#apptAllDay').val() == "true"),
                                  },
                                      true);
                              }
                          },
                      });

  });

                  function isMobileDevice() {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

                  // add event  start date date and time picker 
                  $('#evt-datepicker-start').datetimepicker({
                      format: 'DD-MM-YYYY'
    });

                  // add event End date date and time picker 
                  $('#evt-datepicker-end').datetimepicker({
                      useCurrent: false,
                  format: 'DD-MM-YYYY'
    });

                  // add event start date picker
                  $("#evt-datepicker-start").on("dp.change", function (e) {
                      $('#edit-datepicker-end').data("DateTimePicker").minDate(e.date);
    });
                  // add event end date picker
                  $("#evt-datepicker-end").on("dp.change", function (e) {
                      $('#edit-datepicker-start').data("DateTimePicker").maxDate(e.date);
    });

              </script> */}

              {/* <!-- Scripts Ends --> */}

    </div>
  )
}
