import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, Dropdown, Button, Menu, message, Card, List } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Code } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import EmpCard from "../components/Card";
import { Skeleton } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import SkeletonComp from '../components/SkeletonComp';
import "./EmployeeDashboard.css"

const EmployeeDashboard = ({ admin, }) => {
    let userId = useParams().id;
    console.log("dash", userId, admin);
    const [attendanceData, setAttendanceData] = useState([]);
    const [leavesRemaining, setLeavesRemaining] = useState(2);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [checkOutDisabled, setCheckOutDisabled] = useState(true);
    const [data, setData] = useState([]);
    const [locationAccess, setLocationAccess] = useState(false);
    const [place, setPlace] = useState({});
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState(false)

    useEffect(() => {
        const requestLocationAccess = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let latitude = position.coords.latitude;
                    let longitude = position.coords.longitude;
                    console.log("Location:", latitude, longitude);
                    axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=c9f55425359ee4b9ff48b61b997115c8`).then((resp) => {
                        let loc = resp.data.name;

                        let obj = {
                            lat: latitude,
                            long: longitude,
                            place: loc
                        }

                        setPlace(obj)
                        console.log("geo:", obj)
                        setLocationAccess(true);
                    })
                },
                () => {
                    setLocationAccess(false);
                    message.warning('Please grant location access to continue.');
                }
            );
        };

        requestLocationAccess();
        // Fetch attendance data for the logged-in user from MongoDB
        axios.get(`https://br-attendance-backend.onrender.com/user/${userId}`).then((response) => {
            console.log("dashb", response.data.getdatabyid);
            setLoading(false)
            setData(response.data.getdatabyid)
            const { attendance, leavesRemaining } = response.data.getdatabyid;
            setAttendanceData(attendance);
            setLeavesRemaining(leavesRemaining);
            console.log("yugbjn", leavesRemaining);
            if (response.data.getdatabyid.role === 'admin') {
                setRole(true)
            }
        });
    }, [userId]);

    console.log("employee dashboard time ", new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));

    const handleAttendanceChange = (date, status) => {
        if (status === 'On Leave' || status === 'Leave without Pay' || status === 'Sick Leave') {
            // Check if leavesRemaining is greater than 0
            if (leavesRemaining > 0) {
                const updatedAttendanceData = [...attendanceData];
                const existingRecord = updatedAttendanceData.find((record) => record.date === date.format('YYYY-MM-DD'));

                if (existingRecord) {
                    // Update existing record
                    existingRecord.status = status;
                } else {
                    // Create new record
                    updatedAttendanceData.push({
                        date: date.format('YYYY-MM-DD'),
                        status: status,
                    });
                }

                setAttendanceData(updatedAttendanceData);
                setSelectedStatus(status);
                setLeavesRemaining(leavesRemaining - 1); // Decrease leavesRemaining by 1
            } else {
                message.warning('Insufficient leaves remaining');
            }
        } else {
            const updatedAttendanceData = [...attendanceData];
            const existingRecord = updatedAttendanceData.find((record) => record.date === date.format('YYYY-MM-DD'));

            if (existingRecord) {
                // Update existing record
                existingRecord.status = status;
            } else {
                // Create new record
                updatedAttendanceData.push({
                    date: date.format('YYYY-MM-DD'),
                    status: status,
                    checkIn: (status === 'Work from Home' || status === 'Present') ? (new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })) : "",
                    checkOut: "",
                    lat: place.lat,
                    long: place.long,
                    place: place.place
                });
            }

            setAttendanceData(updatedAttendanceData);
            setSelectedStatus(status);
        }

        // Enable the "Check-out" button if the selected status is "Work from Home" or "Present"
        if (status === 'Work from Home' || status === 'Present') {
            setCheckOutDisabled(false);
        } else {
            setCheckOutDisabled(true);
        }
    };

    const handleSaveAttendance = () => {
        // Save attendance data to MongoDB
        let obj = {
            attendance: attendanceData,
            leavesRemaining: leavesRemaining,
        };
        console.log("obj", obj);
        axios
            .patch(`https://br-attendance-backend.onrender.com/user/update/${userId}`, obj)
            .then(() => {
                message.success('Attendance saved successfully');
            })
            .catch((error) => {
                message.error('Failed to save attendance');
                console.error(error);
            });
    };

    const handleCheckOut = (date) => {
        const existingRecord = attendanceData.find((record) => record.date === date.format('YYYY-MM-DD'));

        if (existingRecord) {
            // Update checkOut field with current UTC date and time
            existingRecord.checkOut = new Date().toUTCString();
            setAttendanceData([...attendanceData]); // Trigger re-render
            setCheckOutDisabled(true); // Disable the "Check-out" button
            message.success('Check-out recorded successfully');
        }
    };

    const dateCellRender = (date) => {


        // const isWeekend = date.day() === 6 || date.day() === 0; // Saturday: 6, Sunday: 0
        const isWeekend = date.day() === 0;
        const attendanceRecord = attendanceData.find((record) => record.date === date.format('YYYY-MM-DD'));

        if (isWeekend) {
            return null; // Do not render cell for weekends
        }

        const handleMenuClick = ({ key }) => {
            handleAttendanceChange(date, key);
        };

        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="Present" disabled={!date.isSame(new Date(), 'day')}>
                    Present
                </Menu.Item>
                <Menu.Item key="On Leave" disabled={leavesRemaining <= 0}>
                    On Leave
                </Menu.Item>
                <Menu.Item key="Work from Home" disabled={!date.isSame(new Date(), 'day')}>
                    Work from Home
                </Menu.Item>
                <Menu.Item key="Leave without Pay" disabled={leavesRemaining <= 0}>
                    Leave without Pay
                </Menu.Item>
                {/* <Menu.Item key="Sick Leave" disabled={leavesRemaining <= 0}>
          Sick Leave
        </Menu.Item> */}
            </Menu>
        );

        const menuAdmin = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key="Present">
                    Present
                </Menu.Item>
                <Menu.Item key="On Leave">
                    On Leave
                </Menu.Item>
                <Menu.Item key="Work from Home">
                    Work from Home
                </Menu.Item>
                <Menu.Item key="Leave without Pay">
                    Leave without Pay
                </Menu.Item>
                <Menu.Item key="Sick Leave">
                    Sick Leave
                </Menu.Item>
            </Menu>
        );

        const dropdownMenu = admin ? menuAdmin : menu;

        return (
            <Dropdown overlay={dropdownMenu} trigger={['click']} disabled={!!attendanceRecord}>
                <Button>{attendanceRecord ? attendanceRecord.status : 'Select'}</Button>
            </Dropdown>
        );
    };

    const leavesRemainingColor = leavesRemaining === 0 ? 'red' : 'green';

    // Function to handle carry forward of unused leaves to the next month
    const handleCarryForwardLeaves = () => {
        // Increment leavesRemaining by 2 for the next month
        setLeavesRemaining((prevLeavesRemaining) => prevLeavesRemaining + 2);
        message.success('Leaves carried forward to the next month');
    };

    return (
        <>
            {/* {locationAccess ?
                <div className="mainCont">

                    
                    {
                        loading ? <SkeletonComp /> : <div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginBottom: '1rem'
                                }}>
                                <code>{!admin ? "Employee Dashboard" : "Admin Dashboard"}</code><br />
                                {admin && <Link to={`/employeelist/`}><Button>Back to User List</Button></Link>}
                              
                            </div>
                            <div id="cardAndButtons"
                                style={{
                                    display: 'flex',
                                    justifyContent: "space-between"
                                 
                                }}>

                                <div id="cardDiv"
                                    style={{
                                        width: "40%",
                                     
                                    }}>
                                    <EmpCard employee={data} />
                                </div>
                                <Card>

                                    <div id='buttonsDiv'
                                        style={{
                                            width: "80%", marginBottom: '1rem',
                                       
                                        }}>
                                        <div >

                                            <Button type="primary" onClick={handleSaveAttendance} disabled={!selectedStatus}>
                                                Save Attendance
                                            </Button><br />
                                        </div>
                                        <div
                                            style={{ width: "0%" }}
                                        >

                                            {admin && (
                                                <Button onClick={handleCarryForwardLeaves}>
                                                    Carry Forward Leaves
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <Calendar dateCellRender={dateCellRender} />
                            {!checkOutDisabled && (
                                <Button type="primary" onClick={() => handleCheckOut(new Date())} disabled={checkOutDisabled}>
                                    Check-out
                                </Button>
                            )}
                        </div>
                    }

                </div>
                : null} */}



            {locationAccess ?
                <div className="mainCont">
                    {loading ? <SkeletonComp /> :
                        <div className="dashboardContainer">
                            <div className="dashboardHeader">
                                {!admin ? <h2>Employee Dashboard</h2> : <h2>Admin Dashboard</h2>}
                                {admin && <Link to={`/employeelist/`}><Button>Back to User List</Button></Link>}
                            </div>
                            <div className="cardAndButtons">
                                <div className="cardDiv">
                                    <EmpCard employee={data} />
                                </div>
                                <Card className="dashboardCard">
                                    <div className="buttonsDiv">
                                        <div>
                                            <Button type="primary" onClick={handleSaveAttendance} disabled={!selectedStatus}>
                                                Save Attendance
                                            </Button>
                                            <Link to={`/addleave/${userId}`}>
                                                <Button>
                                                    Apply Leave
                                                </Button>
                                            </Link>
                                        </div>
                                        {admin &&
                                            <div>
                                                <Button onClick={handleCarryForwardLeaves}>
                                                    Carry Forward Leaves
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </Card>
                            </div>
                            <Calendar dateCellRender={dateCellRender} />
                            {!checkOutDisabled && (
                                <Button type="primary" onClick={() => handleCheckOut(new Date())} disabled={checkOutDisabled}>
                                    Check-out
                                </Button>
                            )}
                        </div>
                    }
                </div>
                : null}


        </>


    );
};

export default EmployeeDashboard;