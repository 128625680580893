import React from 'react';
import { Card, Row, Col, Avatar } from 'antd';

const EmpCard = ({ employee }) => {
    const { name, empId, designation, picUrl } = employee;

    return (
        <Card>
            <Row align="middle">
                <Col span={6}>
                    <Avatar
                        src={picUrl ? picUrl : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPiccyyH4GrWG-DzBCAaRQ9ZFhxnfEe3k4yw&usqp=CAU"}
                        size={64} />
                </Col>
                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            <h3>{name}</h3>
                        </Col>
                        <Col span={24}>
                            <p>Employee ID: {empId}</p>
                        </Col>
                        <Col span={24}>
                            <p>Designation: {designation}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default EmpCard;
