import { path } from 'd3';
import React from 'react'
import { Link } from 'react-router-dom';
import { useState } from 'react';


export default function Navbar({ sideFun, showSide, login, admin, empid }) {
    console.log("loginpage", login);
    console.log("empid", empid)
    let a = login ? `/` : (admin ? `/dashboard` : `/employeedashboard/${empid}`)
    console.log("a", a)

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };






    return (
        <>
            <div style={{ display: "flex", backgroundColor: "teal", zIndex: "102" }}>
                <div style={{ fontSize: "30px" }}><img src={"http://brandradiator.in/wp-content/uploads/2023/05/BR-logo1-1.png"} alt="BR-LOGO" /></div>
                <div></div>
            </div>

        </>


        // <div >
        //     <nav style={{ backgroundColor: "black" }} class="navbar navbar-expand-xl fixed-top navbar-light bg-custom pmd-navbar pmd-z-depth-light-1">

        //         <div>
        //             <button className="burger-button" onClick={toggleMenu}>
        //                 <div className={`burger-icon ${isOpen ? 'open' : ''}`}>
        //                     <span></span><br />
        //                     <span></span><br />
        //                     <span></span>
        //                 </div>
        //             </button>

        //             {isOpen && (
        //                 <div className="menu">
        //                     <ul>
        //                         <li>Menu Item 1</li>
        //                         <li>Menu Item 2</li>
        //                         <li>Menu Item 3</li>
        //                     </ul>
        //                 </div>
        //             )}
        //         </div>

        //         <div class="navbar-header">
        //             <Link to={login ? `/` : (admin ? `/dashboard` : `/employeedashboard/${empid}`)}> <img style={{ width: "70%" }} src="https://brandradiator.com/images/BR-logo1.png" alt="brLogo" /></Link>

        //         </div>

        //         {/* <!-- Search Form --> */}
        //         {/* <form class="col-md-6 col-lg-4 d-none d-md-block ml-lg-5 ml-md-2">
        //           <div class="input-group pmd-search">
        //               <div class="input-group-prepend">
        //                   <span class="input-group-text" id="basic-addon1">
        //                       <i class="material-icons md-dark pmd-icon-sm">search</i>
        //                   </span>
        //               </div>
        //               <input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1"/>
        //           </div>
        //       </form> */}

        //         {/* <!-- Right Menu --> */}
        //         {/* <div class="pmd-navbar-right-icon ml-auto">
        //           <div class="nav-item d-inline-block dropdown pmd-dropdown">
        //               <a href="javascript:void(0);" class="btn btn-sm pmd-btn-fab pmd-btn-flat" id="emailDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //                   <i data-badge="5" class="material-icons pmd-badge pmd-badge-overlap pmd-badge-success md-dark pmd-sm">
        //                       mail_outline
        //                   </i>
        //               </a>
        //               <div class="dropdown-menu dropdown-menu-right notifications-col" aria-labelledby="emailDropdown">
        //                   <div class="card pmd-card mb-0">
        //                       <div class="card-header d-flex w-100 justify-content-between pmd-card-border">
        //                           <h3 class="pmd-list-title mb-0">Emails</h3>
        //                           <a href="inbox.html" class="text-primary"><small>5 New Emails</small></a>
        //                       </div>
        //                       <div class="list-group pmd-list pmd-list-border">
        //                           <a class="list-group-item d-flex flex-row" href="inbox-detail.html">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-1.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">Mitrea</h3>
        //                                   <p class="pmd-list-subtitle">Desktop Application developer expert</p>
        //                               </div>
        //                           </a>
        //                           <a class="list-group-item d-flex flex-row unread" href="inbox-detail.html">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-2.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">Keel</h3>
        //                                   <p class="pmd-list-subtitle">Getting Started with Bootstrap 4 Admin</p>
        //                               </div>
        //                           </a>
        //                           <a class="list-group-item d-flex flex-row" href="inbox-detail.html">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-3.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">John</h3>
        //                                   <p class="pmd-list-subtitle">Website Speed Issues</p>
        //                               </div>
        //                           </a>
        //                           <a class="list-group-item d-flex flex-row" href="inbox-detail.html">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="Keel" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-2.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">Baykam</h3>
        //                                   <p class="pmd-list-subtitle">Develop a simple Amazon Fire Stick TV App</p>
        //                               </div>
        //                           </a>
        //                       </div>
        //                   </div>
        //               </div>
        //           </div>
        //           <div class="nav-item dropdown pmd-dropdown d-inline-block">
        //               <a class="btn btn-sm pmd-btn-fab pmd-btn-flat" href="javascript:void(0);" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        //                   <i data-badge="3" class="material-icons pmd-badge pmd-badge-overlap pmd-badge-success md-dark pmd-sm">notifications_none</i>
        //               </a>
        //               <div class="dropdown-menu dropdown-menu-right notifications-col" aria-labelledby="navbarDropdown">
        //                   <div class="card pmd-card mb-0">
        //                       <div class="card-header d-flex w-100 justify-content-between pmd-card-border">
        //                           <h3 class="pmd-list-title mb-0">Notifications</h3>
        //                           <a href="notifications.html" class="text-primary"><small>3 New Notifications</small></a>
        //                       </div>
        //                       <div class="list-group pmd-list pmd-list-border">
        //                           <a class="list-group-item d-flex flex-row" href="javascript:void(0);">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-1.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title"><span>Prathit</span> posted a new challanegs</h3>
        //                                   <p class="pmd-list-subtitle">5 Minutes ago</p>
        //                               </div>
        //                           </a>
        //                           <a class="list-group-item d-flex flex-row unread" href="javascript:void(0);">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-2.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">Keel Cloned 2 challenges.</h3>
        //                                   <p class="pmd-list-subtitle">15 Minutes ago</p>
        //                               </div>
        //                           </a>
        //                           <a class="list-group-item d-flex flex-row" href="javascript:void(0);">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-3.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">John posted new collection.</h3>
        //                                   <p class="pmd-list-subtitle">25 Minutes ago</p>
        //                               </div>
        //                           </a>
        //                           <a class="list-group-item d-flex flex-row">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="Keel" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-2.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">Today is <strong>Wendell Grover</strong>'s first day.</h3>
        //                                   <p class="pmd-list-subtitle">15 Minutes ago</p>
        //                               </div>
        //                           </a>

        //                           <a class="list-group-item d-flex flex-row">
        //                               <span class="pmd-avatar-list-img">
        //                                   <img alt="Keel" data-src="holder.js/40x40" class="img-fluid" src="themes/images/profile-2.jpg" data-holder-rendered="true">
        //                               </span>
        //                               <div class="media-body">
        //                                   <h3 class="pmd-list-title">Today is <strong>Samuel Smith</strong>'s birthday.</h3>
        //                                   <p class="pmd-list-subtitle">15 Minutes ago</p>
        //                               </div>
        //                           </a>
        //                       </div>
        //                   </div>
        //               </div>
        //           </div>
        //       </div> */}
        //     </nav>
        // </div>
    )
}


{/* <!-- Right Menu --> */ }
{/* <div class="pmd-navbar-right-icon ml-auto">
                  <div class="nav-item d-inline-block dropdown pmd-dropdown">
                      <a href="javascript:void(0);" class="btn btn-sm pmd-btn-fab pmd-btn-flat" id="emailDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i data-badge="5" class="material-icons pmd-badge pmd-badge-overlap pmd-badge-success md-dark pmd-sm">
                              mail_outline
                          </i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right notifications-col" aria-labelledby="emailDropdown">
                          <div class="card pmd-card mb-0">
                              <div class="card-header d-flex w-100 justify-content-between pmd-card-border">
                                  <h3 class="pmd-list-title mb-0">Emails</h3>
                                  <a href="inbox.html" class="text-primary"><small>5 New Emails</small></a>
                              </div>
                              <div class="list-group pmd-list pmd-list-border">
                                  <a class="list-group-item d-flex flex-row" href="inbox-detail.html">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-1.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">Mitrea</h3>
                                          <p class="pmd-list-subtitle">Desktop Application developer expert</p>
                                      </div>
                                  </a>
                                  <a class="list-group-item d-flex flex-row unread" href="inbox-detail.html">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-2.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">Keel</h3>
                                          <p class="pmd-list-subtitle">Getting Started with Bootstrap 4 Admin</p>
                                      </div>
                                  </a>
                                  <a class="list-group-item d-flex flex-row" href="inbox-detail.html">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-3.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">John</h3>
                                          <p class="pmd-list-subtitle">Website Speed Issues</p>
                                      </div>
                                  </a>
                                  <a class="list-group-item d-flex flex-row" href="inbox-detail.html">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="Keel" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-2.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">Baykam</h3>
                                          <p class="pmd-list-subtitle">Develop a simple Amazon Fire Stick TV App</p>
                                      </div>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="nav-item dropdown pmd-dropdown d-inline-block">
                      <a class="btn btn-sm pmd-btn-fab pmd-btn-flat" href="javascript:void(0);" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i data-badge="3" class="material-icons pmd-badge pmd-badge-overlap pmd-badge-success md-dark pmd-sm">notifications_none</i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right notifications-col" aria-labelledby="navbarDropdown">
                          <div class="card pmd-card mb-0">
                              <div class="card-header d-flex w-100 justify-content-between pmd-card-border">
                                  <h3 class="pmd-list-title mb-0">Notifications</h3>
                                  <a href="notifications.html" class="text-primary"><small>3 New Notifications</small></a>
                              </div>
                              <div class="list-group pmd-list pmd-list-border">
                                  <a class="list-group-item d-flex flex-row" href="javascript:void(0);">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-1.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title"><span>Prathit</span> posted a new challanegs</h3>
                                          <p class="pmd-list-subtitle">5 Minutes ago</p>
                                      </div>
                                  </a>
                                  <a class="list-group-item d-flex flex-row unread" href="javascript:void(0);">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-2.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">Keel Cloned 2 challenges.</h3>
                                          <p class="pmd-list-subtitle">15 Minutes ago</p>
                                      </div>
                                  </a>
                                  <a class="list-group-item d-flex flex-row" href="javascript:void(0);">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="40x40" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-3.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">John posted new collection.</h3>
                                          <p class="pmd-list-subtitle">25 Minutes ago</p>
                                      </div>
                                  </a>
                                  <a class="list-group-item d-flex flex-row">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="Keel" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-2.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">Today is <strong>Wendell Grover</strong>'s first day.</h3>
                                          <p class="pmd-list-subtitle">15 Minutes ago</p>
                                      </div>
                                  </a>

                                  <a class="list-group-item d-flex flex-row">
                                      <span class="pmd-avatar-list-img">
                                          <img alt="Keel" data-src="holder.js/40x40" class="img-fluid" src="assets/images/profile-2.jpg" data-holder-rendered="true" />
                                      </span>
                                      <div class="media-body">
                                          <h3 class="pmd-list-title">Today is <strong>Samuel Smith</strong>'s birthday.</h3>
                                          <p class="pmd-list-subtitle">15 Minutes ago</p>
                                      </div>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}