// import React, { useState } from 'react';
// import { Input, Select, Button, message, Card } from 'antd';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const { Option } = Select;

// const AddEmployeeForm = () => {
//     const navigate = useNavigate();

//     const [name, setName] = useState('');
//     const [employeeId, setEmployeeId] = useState('');
//     const [password, setPassword] = useState('');
//     const [pictureUrl, setPictureUrl] = useState('');
//     const [role, setRole] = useState('');
//     const [leavesRemaining, setLeavesRemaining] = useState('');

//     const handleNameChange = (e) => {
//         setName(e.target.value);
//     };

//     const handleEmployeeIdChange = (e) => {
//         setEmployeeId(e.target.value);
//     };

//     const handlePasswordChange = (e) => {
//         setPassword(e.target.value);
//     };

//     const handlePictureUrlChange = (e) => {
//         setPictureUrl(e.target.value);
//     };

//     const handleRoleChange = (value) => {
//         setRole(value);
//     };

//     const handleLeavesRemainingChange = (e) => {
//         setLeavesRemaining(e.target.value);
//     };

//     const handleSubmit = () => {
//         // Create an object with the form data
//         const employeeData = {
//             name: name,
//             empId: employeeId,
//             pwd: password,
//             picUrl: pictureUrl,
//             role,
//             leavesRemaining,
//         };
//         console.log(employeeData);
//         // Send a POST request to the server using axios
//         axios
//             .post('https://br-attendance-backend.onrender.com/user/signup', employeeData)
//             .then((response) => {
//                 const { data } = response;
//                 console.log(data);
//                 navigate(`/employeelist/`);
//             })
//             .catch((error) => {
//                 console.error(error);
//                 message.error('An error occurred while creating the employee.');
//             });
//     };

//     return (
//         <div className=" ">
//             <Card title="Add Employee" className="shadow">
//                 <div>
//                     <Input placeholder="Name" value={name} onChange={handleNameChange} />
//                 </div>
//                 <div>
//                     <Input
//                         placeholder="Employee ID"
//                         value={employeeId}
//                         onChange={handleEmployeeIdChange}
//                     />
//                 </div>
//                 <div>
//                     <Input.Password
//                         placeholder="Password"
//                         value={password}
//                         onChange={handlePasswordChange}
//                     />
//                 </div>
//                 <div>
//                     <Input
//                         placeholder="Picture URL"
//                         value={pictureUrl}
//                         onChange={handlePictureUrlChange}
//                     />
//                 </div>
//                 <div>
//                     <Select
//                         placeholder="Role"
//                         value={role}
//                         onChange={handleRoleChange}
//                         style={{ width: '200px' }}
//                     >
//                         <Option value="admin">Admin</Option>
//                         <Option value="employee">Employee</Option>
//                     </Select>
//                 </div>
//                 <div>
//                     <Input
//                         placeholder="Leaves Remaining"
//                         value={leavesRemaining}
//                         onChange={handleLeavesRemainingChange}
//                     />
//                 </div>
//                 <div className="mt-3">
//                     <Button type="primary" onClick={handleSubmit}>
//                         Create Employee
//                     </Button>
//                 </div>
//             </Card>
//         </div>
//     );
// };

// export default AddEmployeeForm;


// import React, { useState } from 'react';
// import { Input, Button } from 'antd';
// import axios from 'axios';

// const AddEmployeePage = () => {
//     const [name, setName] = useState('');
//     const [employeeId, setEmployeeId] = useState('');
//     const [password, setPassword] = useState('');
//     const [leavesRemaining, setLeavesRemaining] = useState('');
//     const [pictureUrl, setPictureUrl] = useState('');

//     const handleNameChange = (e) => {
//         setName(e.target.value);
//     };

//     const handleEmployeeIdChange = (e) => {
//         setEmployeeId(e.target.value);
//     };

//     const handlePasswordChange = (e) => {
//         setPassword(e.target.value);
//     };

//     const handleLeavesRemainingChange = (e) => {
//         setLeavesRemaining(e.target.value);
//     };

//     const handlePictureUrlChange = (e) => {
//         setPictureUrl(e.target.value);
//     };

//     const handleSubmit = () => {
//         const employeeData = {
//             name: name,
//             empId: employeeId,
//             pwd: password,
//             leavesRemaining: leavesRemaining,
//             picUrl: pictureUrl,
//         };

//         axios
//             .post('https://br-attendance-backend.onrender.com/user/signup', employeeData)
//             .then((response) => {
//                 console.log(response.data);
//                 // Perform any necessary actions after successfully adding an employee
//             })
//             .catch((error) => {
//                 console.error(error);
//                 // Handle error case
//             });
//     };

//     return (
//         <div className="pmd-content admin section-container">
//             <h1>Add Employee</h1>
//             <nav aria-label="breadcrumb">
//                 <ol className="breadcrumb pmd-breadcrumb">
//                     <li className="breadcrumb-item">
//                         <a href="dashboard.html">Dashboard</a>
//                     </li>
//                     <li className="breadcrumb-item">Employees</li>
//                     <li className="breadcrumb-item active" aria-current="page">
//                         Add Employee
//                     </li>
//                 </ol>
//             </nav>

//             <div className="page-content">
//                 <div role="tabpanel" className="tab-pane active" id="about">
//                     <div className="card pmd-card">
//                         <form>
//                             <div className="details-tab">
//                                 <h3 className="card-title">Basic Information</h3>
//                                 <div className="row">
//                                     <div className="col-12 col-sm-6 col-md-3">
//                                         <div className="form-group pmd-textfield pmd-textfield-floating-label">
//                                             <label htmlFor="fn" className="col-form-label control-label">
//                                                 Name
//                                             </label>
//                                             <Input type="text" value={name} onChange={handleNameChange} />
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-sm-6 col-md-3">
//                                         <div className="form-group pmd-textfield pmd-textfield-floating-label">
//                                             <label htmlFor="ln" className="col-form-label control-label">
//                                                 Employee ID
//                                             </label>
//                                             <Input type="text" value={employeeId} onChange={handleEmployeeIdChange} />
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-sm-6 col-md-3">
//                                         <div className="form-group pmd-textfield pmd-textfield-floating-label">
//                                             <label htmlFor="mn" className="col-form-label control-label">
//                                                 Password
//                                             </label>
//                                             <Input type="password" value={password} onChange={handlePasswordChange} />
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-sm-6 col-md-3">
//                                         <div className="form-group pmd-textfield pmd-textfield-floating-label">
//                                             <label htmlFor="phone" className="col-form-label control-label">
//                                                 Leaves Remaining
//                                             </label>
//                                             <Input type="text" value={leavesRemaining} onChange={handleLeavesRemainingChange} />
//                                         </div>
//                                     </div>
//                                     <div className="col-12 col-sm-6 col-md-3">
//                                         <div className="form-group pmd-textfield pmd-textfield-floating-label">
//                                             <label htmlFor="phone" className="col-form-label control-label">
//                                                 Picture URL
//                                             </label>
//                                             <Input type="text" value={pictureUrl} onChange={handlePictureUrlChange} />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="card-footer pmd-card-border">
//                                 <Button className="btn pmd-ripple-effect btn-primary pmd-btn-raised" onClick={handleSubmit}>
//                                     Save
//                                 </Button>
//                                 <Button className="btn pmd-ripple-effect btn-outline-secondary" type="button">
//                                     Reset
//                                 </Button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddEmployeePage;


import React, { useState } from 'react';
import { Input, Button, Row, Col, Select } from 'antd';
import axios from 'axios';
import { useNavigate,Link } from 'react-router-dom';

const { Option } = Select;

const AddEmployeePage = () => {
    const [name, setName] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [password, setPassword] = useState('');
    const [designation, setDesignation] = useState('');
    const [leavesRemaining, setLeavesRemaining] = useState('');
    const [pictureUrl, setPictureUrl] = useState('');
    const [role, setRole] = useState('');

    const navigate = useNavigate();
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmployeeIdChange = (e) => {
        setEmployeeId(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleDesignationChange = (e) => {
        setDesignation(e.target.value);
    };

    const handleLeavesRemainingChange = (e) => {
        setLeavesRemaining(e.target.value);
    };

    const handlePictureUrlChange = (e) => {
        setPictureUrl(e.target.value);
    };

    const handleRoleChange = (value) => {
        setRole(value);
    };

    const handleSubmit = () => {
        const employeeData = {
            name: name,
            empId: employeeId,
            pwd: password,
            designation:designation,
            leavesRemaining: parseInt(leavesRemaining),
            picUrl: pictureUrl,
            role: role,
        };
        console.log(employeeData)
        axios
            .post('https://br-attendance-backend.onrender.com/user/signup', employeeData)
            .then((response) => {
                console.log(response.data);

                navigate(`/employeelist/`)
                // Perform any necessary actions after successfully adding an employee
            })
            .catch((error) => {
                console.error(error);
                // Handle error case
            });
    };

    const handleReset = () => {
        setName('');
        setEmployeeId('');
        setPassword('');
        setDesignation('');
        setLeavesRemaining('');
        setPictureUrl('');
        setRole('');
    };

    return (
        <div className="pmd-content admin section-container">
            <h1>Add Employee</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pmd-breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item"><Link to="/employeelist">Employee List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to="/addemployeepage"> Add Employee</Link>
                    </li>
                </ol>
            </nav>

            <div className="page-content">
                <div role="tabpanel" className="tab-pane active" id="about">
                    <div className="card pmd-card">
                        <form>
                            <div className="details-tab">
                                <h3 className="card-title">Employee Information</h3>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Input
                                                placeholder="Name"
                                                value={name}
                                                onChange={handleNameChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Input
                                                placeholder="Employee ID"
                                                value={employeeId}
                                                onChange={handleEmployeeIdChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Input
                                                placeholder="Password"
                                                type="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Input
                                                placeholder="Designation"
                                                type="text"
                                                value={designation}
                                                onChange={handleDesignationChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Input
                                                placeholder="Leaves Remaining"
                                                value={leavesRemaining}
                                                onChange={handleLeavesRemainingChange}
                                                // type='n'
                                            />
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Input
                                                placeholder="Picture URL"
                                                value={pictureUrl}
                                                onChange={handlePictureUrlChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={21}>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <Select
                                                placeholder="Select Role"
                                                value={role}
                                                onChange={handleRoleChange}
                                            >
                                                
                                                <Option value="admin"> Admin</Option>
                                                <Option value="employee"> Employee</Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="card-footer pmd-card-border">
                                <Button
                                    className="btn pmd-ripple-effect btn-primary pmd-btn-raised"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                                <Button
                                    className="btn pmd-ripple-effect btn-outline-secondary"
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEmployeePage;


