import { border } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
    return (
        <div >
            {/* <div class="pmd-sidebar-overlay"></div> */}

            {/* <!-- Left sidebar --> */}
            <div style={{ margin: "8px", border: "4px solid red" }}>
                <div style={{ width: "100px", height: "100px", border: "2px solid white" }}>Hello</div>
                <aside id="basicSidebar" class="pmd-sidebar pmd-sidebar-dark pmd-sidebar-slide-push pmd-sidebar-open bg-primary pmd-z-depth-light-2" role="navigation">
                    <ul class="nav flex-column pmd-sidebar-nav">
                        <div style={{ fontSize: "20px", color: "white" }}>hello DEbo</div>

                        {/* <!-- User info --> */}
                        <li class="nav-item pmd-user-info mb-0">
                            {/* <a href="javascript:void(0);" class="nav-link btn-user text-center"> */}
                            <img class="rounded-circle m-auto" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPiccyyH4GrWG-DzBCAaRQ9ZFhxnfEe3k4yw&usqp=CAU" width="40" height="40" alt="avatar" />
                            <h3 class="pmd-title m-0">BR Admin</h3>
                            <p class="pmd-subheading1 m-0">info@brandradiator.com</p>
                            {/* </a> */}
                        </li>
                        {/* <!-- End user info --> */}

                        <li class="nav-item">
                            {/* <a class="nav-link pmd-ripple-effect" href="dashboard.html"> */}
                            <Link to='/dashboard' class="nav-link pmd-ripple-effect" >
                                <i class="material-icons pmd-list-icon pmd-sm md-light">
                                    dashboard
                                </i>
                                <span class="media-body">Dashboard</span>
                            </Link>
                        </li>

                        <li class="nav-item">
                            <Link to='/employeelist/' class="nav-link" data-toggle="collapse" >

                                <i class="material-icons pmd-list-icon pmd-sm md-light">
                                    people
                                </i>
                                <span class="media-body">Employees List</span>
                                <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                            </Link>
                        </li>

                        <li class="nav-item">
                            <Link to='/addemployeepage' class="nav-link" data-toggle="collapse" >

                                <i class="material-icons pmd-list-icon pmd-sm md-light">
                                    people
                                </i>
                                <span class="media-body">Add Employees</span>
                                <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                            </Link>
                        </li>

                        {/* <li class="nav-item">
                      <Link to='employeelist' class="nav-link" data-toggle="collapse" >

                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              people
                          </i>
                          <span class="media-body">Employee's Detail </span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link>
                  </li> */}

                        {/* <ul class="collapse" id="employee-menu" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="employee-list-grid-view.html">Employee List</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-list-with-datatable.html">Employee List with Data Table</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="add-employee.html">Add Employee</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link pmd-ripple-effect" href="employee-detail.html">Employee Detail</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-blank-state.html">Employee Blank State</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="employee-search-no-result-found.html">Employee Search No Result Found</a>
                          </li>
                      </ul> */}


                        {/* <li class="nav-item">
                       <a class="nav-link pmd-ripple-effect" href="interview.html"> 
                      <a class="nav-link pmd-ripple-effect" href="#">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              dashboard
                          </i>
                          <span class="media-body">Interview</span>
                      </a>
                  </li> */}

                        <li class="nav-item">
                            <Link to="/leavepage" class="nav-link pmd-ripple-effect" >
                                <i class="material-icons pmd-list-icon pmd-sm md-light">
                                    date_range
                                </i>
                                <span class="media-body">Leave</span>
                            </Link>
                        </li>

                        {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="inbox.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              inbox
                          </i>
                          <span class="media-body">Inbox</span>
                      </a>
                  </li> */}

                        {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="calendar.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              today
                          </i>
                          <span class="media-body">Event Calendar</span>
                      </a>
                  </li> */}

                        {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="notifications.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              notifications
                          </i>
                          <span class="media-body">Notifications</span>
                      </a>
                  </li> */}

                        {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="email-templates.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              web
                          </i>
                          <span class="media-body">Email Templates</span>
                      </a>
                  </li> */}

                        {/* <li class="nav-item">
                      <Link to="/" class="nav-link" data-toggle="collapse">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              exit_to_app
                          </i>
                          <span class="media-body">Login</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link> */}
                        {/* <ul class="collapse" id="login-menu" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="login.html">Login Option 1</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="login2.html">Login Option 2</a>
                          </li>
                      </ul> */}
                        {/* </li> */}

                        {/* <li class="nav-item">
                      <a class="nav-link" href="contact.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              call
                          </i>
                          <span class="media-body">Contact</span>
                      </a>
                  </li> */}

                        {/* <li class="nav-item"> */}
                        {/* <Link to="/aboutpage" class="nav-link" data-toggle="collapse" >
                          <i class="material-icons pmd-list-icon pmd-sm">
                              pages
                          </i>
                          <span class="media-body">About</span>
                          <div class="media-right media-bottom"><i class="dic-more-vert dic"></i></div>
                      </Link> */}
                        {/* <ul class="collapse" id="thirdpartyelements" data-parent="#basicSidebar">
                          <li class="nav-item">
                              <a class="nav-link" href="about.html">About</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="alert.html">Alerts and Warnings</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="404.html">404</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="404-with-sidebar.html">404 with Sidebar</a>
                          </li>
                      </ul> */}
                        {/* </li> */}
                        {/* <li class="nav-item">
                      <a class="nav-link pmd-ripple-effect" href="account-settings.html">
                          <i class="material-icons pmd-list-icon pmd-sm md-light">
                              settings
                          </i>
                          <span class="media-body">Account Settings</span>
                      </a>
                  </li> */}
                        <li class="nav-item">
                            <a class="nav-link" href="login.html">
                                <i class="material-icons pmd-list-icon pmd-sm">power_settings_new</i>
                                <span class="media-body">Logout</span>
                            </a>
                        </li>
                    </ul>
                </aside>
            </div>
        </div>
    )
}
