import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom';

export default function Leave() {
    return (
        <div id='mainCont'
            style={{ display: "flex" }}
        >
            <div style={{ width: "25%" }} id='sb'>
                <Sidebar />
            </div>
            <div style=
                {{
                    width: "75%",
                    margin: "auto",
                    marginTop: "10%",
                }} >
                <div>
                    {/* <!-- Header Starts --> */}
                    {/* <Navbar /> */}
                    {/* <!-- Header Ends --> */}

                    {/* <!-- Sidebar Starts --> */}
                    <div class="pmd-sidebar-overlay"></div>


                    <div id="content" class="pmd-content inner-page admin">

                        {/* <!-- Title --> */}
                        <h1 id="leave">Leave</h1>
                        {/* <!-- End Title -->
                  <!--breadcrum start--> */}
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb pmd-breadcrumb mb-0">
                                <li class="breadcrumb-item">
                                    <a href="dashboard.html">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Leave</li>
                            </ol>
                        </nav>
                        {/* <!--breadcrum end--> */}

                        <section class="component-section" id="employee">
                            <table class="table pmd-table table-hover pmd-table-card">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Employee</th>
                                        <th>Leave Type</th>
                                        <th>Half Day</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Reason</th>
                                        <th>No of Leave</th>
                                        <th>Paid / Unpaid</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-title="Employee">Tarah L. Faulkner</td>
                                        <td data-title="Leave Type">Casual Leave</td>
                                        <td data-title="Half Day">No</td>
                                        <td data-title="Start Date">1<sup>st</sup> September, 2018</td>
                                        <td data-title="End Date">3<sup>rd</sup> September, 2018</td>
                                        <td data-title="Reason">Attend Cousin's Wedding</td>
                                        <td data-title="No of Leave">3</td>
                                        <td data-title="Paid / Unpaid">Paid</td>
                                        <td data-title="Status">Pending</td>
                                        <td data-title="">
                                            <a href="javascript:void(0);" title="Accept" class="pmd-btn-fab btn-xs btn-outline-secondary btn mr-2 pmd-ripple-effect">
                                                <i class="material-icons">done</i>
                                            </a>
                                            <a href="javascript:void(0);" title="Reject" class="pmd-btn-fab btn-xs btn-outline-danger btn pmd-ripple-effect" data-toggle="modal" data-target="#reject-modal">
                                                <i class="material-icons">close</i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-title="Employee">James L. Faulkner</td>
                                        <td data-title="Leave Type">Sick Leave</td>
                                        <td data-title="Half Day">No</td>
                                        <td data-title="Start Date">1<sup>st</sup> September, 2018</td>
                                        <td data-title="End Date">3<sup>rd</sup> September, 2018</td>
                                        <td data-title="Reason">Having Fever</td>
                                        <td data-title="No of Leave">3</td>
                                        <td data-title="Paid / Unpaid">Paid</td>
                                        <td data-title="Status">Approved</td>
                                        <td data-title=""></td>
                                    </tr>
                                    <tr>
                                        <td data-title="Employee">Maria James</td>
                                        <td data-title="Leave Type">Casual Leave</td>
                                        <td data-title="Half Day">No</td>
                                        <td data-title="Start Date">1<sup>st</sup> November, 2018</td>
                                        <td data-title="End Date">3<sup>rd</sup> November, 2018</td>
                                        <td data-title="Reason">Vacation with Family</td>
                                        <td data-title="No of Leave">3</td>
                                        <td data-title="Paid / Unpaid">Paid</td>
                                        <td data-title="Status">Cancelled</td>
                                        <td data-title="">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-title="Employee">Julia Warne</td>
                                        <td data-title="Leave Type">Sick Leave</td>
                                        <td data-title="Half Day">Yes</td>
                                        <td data-title="Start Date">1<sup>st</sup> December, 2018</td>
                                        <td data-title="End Date">1<sup>st</sup> December, 2018</td>
                                        <td data-title="Reason">Not feeling well</td>
                                        <td data-title="No of Leave">0.5</td>
                                        <td data-title="Paid / Unpaid">Paid</td>
                                        <td data-title="Status">Pending</td>
                                        <td data-title="">
                                            <a href="javascript:void(0);" title="Accept" class="pmd-btn-fab btn-xs btn-outline-secondary btn mr-2 pmd-ripple-effect">
                                                <i class="material-icons">done</i>
                                            </a>
                                            <a href="javascript:void(0);" title="Reject" class="pmd-btn-fab btn-xs btn-outline-danger btn pmd-ripple-effect" data-toggle="modal" data-target="#reject-modal">
                                                <i class="material-icons">close</i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td data-title="Employee">James Jhonathan</td>
                                        <td data-title="Leave Type">Casual Leave</td>
                                        <td data-title="Half Day">No</td>
                                        <td data-title="Start Date">1<sup>st</sup> November, 2018</td>
                                        <td data-title="End Date">3<sup>rd</sup> November, 2018</td>
                                        <td data-title="Reason">Vacation with Family</td>
                                        <td data-title="No of Leave">3</td>
                                        <td data-title="Paid / Unpaid">Paid</td>
                                        <td data-title="Status">Unapproved</td>
                                        <td data-title="">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>

                    <Footer />
                    {/* <!-- Footer Ends --> */}

                    {/* <!-- Apply Leave Floating Action Button --> */}
                    <div class="menu pmd-floating-action" role="navigation">
                        <Link to="/admin/addleave" class="pmd-floating-action-btn btn pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-secondary" data-title="Apply Leave">
                            <i class="material-icons pmd-sm">add</i>
                        </Link>
                    </div>

                    {/* <!-- Reject Leave Request Modal --> */}
                    <div class="modal pmd-modal fade text-center delete-modal" id="reject-modal" tabindex="-1" role="dialog" aria-labelledby="reject-modal" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <i class="material-icons md-danger pmd-icon-circle bg-danger pmd-icon-md"><strong>error_outline</strong></i>
                                </div>
                                {/* <!-- Modal Body --> */}
                                <div class="modal-body">
                                    <h2 class="modal-title">Are you sure?</h2>
                                    <p>Do you really want to reject the employee's leave? <br /> This process cannot be undone.</p>
                                    <a href="javascript:void(0);" class="btn btn-outline-dark pmd-btn-raised pmd-ripple-effect" title="Cancel" data-dismiss="modal">Cancel</a>
                                    <a href="javascript:void(0);" class="btn btn-primary pmd-btn-raised pmd-ripple-effect" title="Delete">Reject</a>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}
