// import React from 'react'
// import Footer from '../components/Footer'

// // const Login = () => {
// //     return (
// //         <div>
// //             <div class="body-custom mb-0">
// //                 <div class="user-onboarding">
// //                     <div class="container">
// //                         <div class="login-container row d-flex justify-content-between">
// //                             {/* <div class="col-sm-6 align-self-md-center login-content">
// //                                 <h1 class="newlogin-logo">Bootstrap 4 <span>Admin</span> Theme</h1>
// //                                 <hr />
// //                                 <h3 class="pmd-display5 text-white">The Bootstrap 4 Admin Theme by Propeller is a full featured premium HRMS Admin theme built using Propeller Pro framework.</h3>
// //                                 <p class="lead">The theme suitable for building CMS, CRM, ERP, Admin Panel, or a web application.</p>
// //                             </div> */}
// //                             <div class="col-sm-6 align-self-md-center">
// //                                 <div class="new-logincard card pmd-card">
// //                                     <div class="login-card">
// //                                         <div class="card-header">
// //                                             <h2 class="card-title">Login here!</h2>

// //                                         </div>
// //                                         <form>
// //                                             <div class="card-body">
// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon mb-3">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">perm_identity</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="exampleInputAmount">EmployeeId</label>
// //                                                         <input type="text" class="form-control" aria-describedby="basic-addon3" id="exampleInputAmount" />

// //                                                     </div>
// //                                                 </div>

// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">lock_outline</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="exampleInputAmount1">Password</label>
// //                                                         <input type="password" class="form-control" aria-describedby="basic-addon3" id="exampleInputAmount1" />
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                             <div class="card-footer text-center">
// //                                                 <div class="form-group clearfix">
// //                                                     <div class="float-left">
// //                                                         <div class="custom-control custom-checkbox pmd-checkbox">
// //                                                             <input class="custom-control-input" type="checkbox" value="" id="inverse_defaultCheck1" checked />
// //                                                             <label class="custom-control-label" for="inverse_defaultCheck1">
// //                                                                 Remember Me
// //                                                             </label>
// //                                                         </div>
// //                                                     </div>
// //                                                     <span class="float-right">
// //                                                         <a href="javascript:void(0);" class="forgot-password text-primary">Forgot password?</a>
// //                                                     </span>
// //                                                 </div>
// //                                                 <a href="dashboard.html" class="btn pmd-ripple-effect btn-primary btn-block btn-lg pmd-btn-raised m-0">Login</a>
// //                                                 <p class="mt-3 mb-0 mb-0">Don't have an account? <a href="javascript:void(0);" class="login-register text-primary">Sign Up</a>. </p>
// //                                             </div>
// //                                         </form>
// //                                     </div>

// //                                     <div class="register-card">
// //                                         <div class="card-header">
// //                                             <h2 class="card-title">New to <span>Admin?</span></h2>
// //                                             <p class="card-subtitle">Start your free account now.</p>
// //                                         </div>
// //                                         <form>
// //                                             <div class="card-body">
// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon mb-3">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">perm_identity</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="exampleInputAmount">Username</label>
// //                                                         <input type="text" class="form-control" aria-describedby="basic-addon3" id="exampleInputAmount" />
// //                                                     </div>
// //                                                 </div>

// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon mb-3">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">email</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="exampleInputAmount">Email address</label>
// //                                                         <input type="email" class="form-control" aria-describedby="basic-addon3" id="exampleInputAmount" />
// //                                                     </div>
// //                                                 </div>

// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon mb-3">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">lock_outline</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="exampleInputAmount1">Password</label>
// //                                                         <input type="password" class="form-control" aria-describedby="basic-addon3" id="exampleInputAmount1" />
// //                                                     </div>
// //                                                 </div>
// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">lock_outline</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="confirm-password">Confirm Password</label>
// //                                                         <input type="password" class="form-control" aria-describedby="basic-addon3" id="confirm-password" />
// //                                                     </div>
// //                                                 </div>
// //                                             </div>

// //                                             <div class="card-footer text-center">
// //                                                 <a href="dashboard.html" class="btn pmd-ripple-effect btn-primary btn-block btn-lg pmd-btn-raised m-0">Sign Up</a>
// //                                                 <p class="mt-3 mb-0">Already have an account? <a href="javascript:void(0);" class="register-login text-primary">Sign In</a>. </p>
// //                                             </div>
// //                                         </form>
// //                                     </div>

// //                                     <div class="forgot-password-card">
// //                                         <div class="card-header">
// //                                             <h2 class="card-title">Forgot password?</h2>
// //                                             <p class="card-subtitle">Submit your email address and we'll send you a link to reset your password.</p>
// //                                         </div>
// //                                         <form>
// //                                             <div class="card-body">
// //                                                 <div class="input-group pmd-input-group pmd-input-group-icon mb-3">
// //                                                     <div class="input-group-prepend">
// //                                                         <div class="input-group-text">
// //                                                             <i class="material-icons md-dark pmd-sm">email</i>
// //                                                         </div>
// //                                                     </div>
// //                                                     <div class="pmd-textfield pmd-textfield-floating-label">
// //                                                         <label for="exampleInputAmount">Email address</label>
// //                                                         <input type="email" class="form-control" aria-describedby="basic-addon3" id="exampleInputAmount" />
// //                                                     </div>
// //                                                 </div>
// //                                             </div>
// //                                             <div class="card-footer text-center">
// //                                                 <a href="dashboard.html" class="btn pmd-ripple-effect btn-primary btn-block btn-lg pmd-btn-raised m-0">Submit</a>
// //                                                 <p class="mt-3 mb-0">Already registered? <a href="javascript:void(0);" class="register-login text-primary">Sign In</a></p>
// //                                             </div>
// //                                         </form>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                         </div>
// //                         <div class="position-relative">
// //                             <Footer />
// //                         </div>
// //                     </div>
// //                 </div>

// //             </div>

// //         </div>

// //     )
// // }

// // export default Login

// import axios from "axios";
// import { Box, Input, Button } from "@chakra-ui/react";
// import { Link, useNavigate } from 'react-router-dom';
// import { useEffect, useState } from "react";
// import { message, Spin } from 'antd';
// // import 'antd/dist/antd.css';
// // import 'antd/dist/antd.min.css';
// import { ProgressBar } from '@ramonak/react-progress-bar';

// export default function Login({ logFunc, admin }) {
//     console.log("admin", admin)
//     const [loading, setLoading] = useState(false);
//     const [locationAccess, setLocationAccess] = useState(false);
//     const [place, setPlace] = useState({});
//     const[adminBtn,setAdminBtn]=useState(false)
//     // const [admin1,setAdmin1] = useState(false);
//     const [progress, setProgress] = useState(0);

//     useEffect(() => {
//         const requestLocationAccess = () => {
//             navigator.geolocation.getCurrentPosition(
//                 (position) => {
//                     let latitude = position.coords.latitude;
//                     let longitude = position.coords.longitude;

//                     axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=c9f55425359ee4b9ff48b61b997115c8`)
//                         .then((resp) => {
//                             console.log("resp", resp)
//                             let loc = resp.data.name;

//                             let obj = {
//                                 lat: latitude,
//                                 long: longitude,
//                                 place: loc,
//                                 date: new Date().toISOString()
//                             };

//                             setPlace(obj);
//                             setLocationAccess(true);
//                         })
//                         .catch(() => {
//                             setLocationAccess(false);
//                             message.warning('Please grant location access to continue.');
//                         });
//                 },
//                 () => {
//                     setLocationAccess(false);
//                     message.warning('Please grant location access to continue.');
//                 }
//             );
//         };

//         requestLocationAccess();
//     }, []);

//     // const loaderFunc = (x) => {
//     //     setLoading(x);
//     // };
//     const loaderFunc = (x) => {
//         setLoading(x);
//         if (x) {
//             setProgress(0); // Reset the progress to 0 when loading starts
//         } else {
//             setProgress(100); // Set the progress to 100 when loading finishes
//         }
//     };

//     const navigate = useNavigate();
//     const login = async (e) => {
//         loaderFunc(true);
//         e.preventDefault();
//         let empId = document.getElementById("empId").value;
//         let pwd = document.getElementById("pwd").value;

//         let obj = {
//             empId: empId,
//             pwd: pwd,
//             role: adminBtn ? "admin" : "employee"
//         };
//         console.log(obj)
//         axios.post("https://br-attendance-backend.onrender.com/user/signin", obj)
//             .then((data) => {
//                 console.log("axiosPost",data)
//                 loaderFunc(false);
//                 // logFunc(false);

//                 let { location } = data.data.data;
//                 console.log("location", data.data.data.location)
//                 location.push(place);

//                 axios.patch(`https://br-attendance-backend.onrender.com/user/update/${data.data.data._id}`, {
//                     location: location
//                 })
//                     .then(() => {
//                         if (!adminBtn) {
//                             navigate(`/employeedashboard/${data.data.data._id}`);
//                         } else {
//                             navigate(`/dashboard`);
//                         }
//                     })
//                     .catch((err) => {
//                         console.log(err);
//                     });
//             })
//             .catch((error) => {
//                 console.error(error)
//                 message.warning(error.response.data.message);
//                 loaderFunc(false);
//             });
//     };

//     return (
//         <div>
//             <div className="body-custom mb-0">
//                 <div className="user-onboarding">
//                     <div className="container">
//                         <div className="login-container row d-flex justify-content-between">
//                             <div className="col-sm-6 align-self-md-center">
//                                 <div className="new-logincard card pmd-card">
//                                     <div  className="login-card">
//                                         <div className="card-header">
//                                             <h2 className="card-title">{!adminBtn?"Login here!":"Admin Login"}</h2>
//                                         </div>
//                                         <form>
//                                             <div className="card-body">
//                                                 <div className="input-group pmd-input-group pmd-input-group-icon mb-3">
//                                                     <div className="input-group-prepend">
//                                                         <span className="input-group-text pmd-textfield" >
//                                                             <i className="fa fa-envelope-o"></i>
//                                                         </span>
//                                                     </div>
//                                                     <Input
//                                                         type="text"
//                                                         className="form-control"
//                                                         placeholder="Enter Employee ID"
//                                                         required
//                                                         id="empId"
//                                                     />
//                                                 </div>

//                                                 <div className="input-group pmd-input-group pmd-input-group-icon mb-3">
//                                                     <div className="input-group-prepend">
//                                                         <span className="input-group-text pmd-textfield" >
//                                                             <i className="fa fa-lock"></i>
//                                                         </span>
//                                                     </div>
//                                                     <Input
//                                                         type="password"
//                                                         className="form-control"
//                                                         placeholder="Enter Password"
//                                                         required
//                                                         id="pwd"
//                                                     />
//                                                 </div>

//                                                 {locationAccess ? (
//                                                     <Box mb={3}>
//                                                         <p className="text-center">
//                                                             Logging from: <strong>{place.place}</strong>
//                                                         </p>
//                                                     </Box>
//                                                 ) : (
//                                                     <Spin tip="Fetching location..." spinning={!locationAccess} />
//                                                 )}
//                                             </div>

//                                             <div className="card-footer">
//                                                 <div className="form-group text-center">
//                                                     <Button
//                                                         type="submit"
//                                                         className="btn btn-primary pmd-btn-outline pmd-ripple-effect"
//                                                         onClick={login}
//                                                         disabled={loading}
//                                                     >
//                                                         {loading ? 'Loading...' : "Log In"}
//                                                     </Button>
//                                                 </div>
//                                                 <div>{!adminBtn ? "Login for" : "Login for"} <span> <strong style={{ color: "blue" }} onClick={() => {setAdminBtn(!adminBtn);  console.log(admin) }} > {!adminBtn ? "Admin" : "Employee"}</strong></span></div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

import axios from 'axios';
import { Box, Input, Button } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import ProgressBar from '@ramonak/react-progress-bar';

export default function Login({ logFunc, admin, loginF, idFun, navF }) {
    console.log('admin', admin);
    const [loading, setLoading] = useState(false);
    const [locationAccess, setLocationAccess] = useState(false);
    const [place, setPlace] = useState({});
    const [adminBtn, setAdminBtn] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        loginF(true);
        const requestLocationAccess = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let latitude = position.coords.latitude;
                    let longitude = position.coords.longitude;

                    axios
                        .get(
                            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=c9f55425359ee4b9ff48b61b997115c8`
                        )
                        .then((resp) => {
                            console.log('resp', resp);
                            let loc = resp.data.name;

                            let obj = {
                                lat: latitude,
                                long: longitude,
                                place: loc,
                                date: new Date().toISOString(),
                            };

                            setPlace(obj);
                            setLocationAccess(true);
                        })
                        .catch(() => {
                            setLocationAccess(false);
                            message.warning('Please grant location access to continue.');
                        });
                },
                () => {
                    setLocationAccess(false);
                    message.warning('Please grant location access to continue.');
                }
            );
        };

        requestLocationAccess();
    }, []);

    const loaderFunc = (x) => {
        setLoading(x);
        if (x) {
            setProgress(0); // Reset the progress to 0 when loading starts
        } else {
            setProgress(100); // Set the progress to 100 when loading finishes
        }
    };

    const navigate = useNavigate();
    const login = async (e) => {
        loaderFunc(true);
        e.preventDefault();
        let empId = document.getElementById('empId').value;
        let pwd = document.getElementById('pwd').value;

        let obj = {
            empId: empId,
            pwd: pwd,
            role: adminBtn ? 'admin' : 'employee',
        };
        console.log(obj);

        const config = {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                );
                console.log('stats', progressEvent.loaded, progressEvent.total);
                setProgress(progress);
            },
        };

        try {
            const response = await axios.post(
                'https://br-attendance-backend.onrender.com/user/signin',
                obj,
                config
            );
            loaderFunc(false);
            loginF(false);
            idFun(response.data.data._id);
            let { location } = response.data.data;
            location.push(place);

            await axios.patch(
                `https://br-attendance-backend.onrender.com/user/update/${response.data.data._id}`,
                {
                    location: location,
                }
            );

            if (!adminBtn) {
                navF(false);
                navigate(`/employeedashboard/${response.data.data._id}`);
            } else {
                navF(true);
                navigate(`/dashboard`);
            }
        } catch (error) {
            console.error(error);
            console.log('progress', progress);
            message.warning(error.response.data.message);
            loaderFunc(false);
            setLoading(false);
            setProgress(0);
        }
    };

    return (
        <div>
            <div className="body-custom mb-0">
                <div className="user-onboarding">
                    <div className="container">
                        <div className="login-container row d-flex justify-content-between">
                            <div className="col-sm-6 align-self-md-center">
                                <div className="new-logincard card pmd-card">
                                    <div className="login-card">
                                        <div className="card-header">
                                            <h2 className="card-title">
                                                {!adminBtn ? 'Login here!' : 'Admin Login'}
                                            </h2>
                                        </div>
                                        <form>
                                            <div className="card-body">
                                                <div className="input-group pmd-input-group pmd-input-group-icon mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text pmd-textfield">
                                                            <i className="fa fa-envelope-o"></i>
                                                        </span>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Employee ID"
                                                        required
                                                        id="empId"
                                                    />
                                                </div>

                                                <div className="input-group pmd-input-group pmd-input-group-icon mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text pmd-textfield">
                                                            <i className="fa fa-lock"></i>
                                                        </span>
                                                    </div>
                                                    <Input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Enter Password"
                                                        required
                                                        id="pwd"
                                                    />
                                                </div>

                                                {locationAccess ? (
                                                    <Box mb={3}>
                                                        <p className="text-center">
                                                            Logging from: <strong>{place.place}</strong>
                                                        </p>
                                                    </Box>
                                                ) : (
                                                    <Spin
                                                        tip="Fetching location..."
                                                        spinning={!locationAccess}
                                                    />
                                                )}
                                            </div>

                                            <div className="card-footer">
                                                <div className="form-group text-center">
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-primary pmd-btn-outline pmd-ripple-effect"
                                                        onClick={login}
                                                        disabled={loading}
                                                    >
                                                        {loading ? 'Loading...' : 'Log In'}
                                                    </Button>
                                                </div>
                                                <div>
                                                    {!adminBtn ? 'Login for' : 'Login for'}{' '}
                                                    <span>
                                                        {' '}
                                                        <strong
                                                            style={{ color: 'blue' }}
                                                            onClick={() => {
                                                                setAdminBtn(!adminBtn);
                                                                console.log(admin);
                                                            }}
                                                        >
                                                            {' '}
                                                            {!adminBtn ? 'Admin' : 'Employee'}
                                                        </strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                        <ProgressBar
                                            completed={progress}
                                            bgColor="#1c5bb2"
                                            baseBgColor="#6db6ff"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
