import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmployeeCards from './EmployeeCards';
import axios from 'axios';
import { Button } from 'antd';
import ListEmployee from './ListEmployeePage';
import Sidebar from '../components/Sidebar';
import { border } from '@chakra-ui/react';
import { Placeholder } from 'react-bootstrap';


export default function EmployeeList() {
    const [visibleUsers, setVisibleUsers] = useState([]);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [view, setView] = useState(true);
    const [filterDesignation, setFilterDesignation] = useState('');

    useEffect(() => {
        axios.get('https://br-attendance-backend.onrender.com/user').then((response) => {
            setAllUsers(response.data.getdata);
        });
    }, []);

    useEffect(() => {
        setVisibleUsers(allUsers.slice(0, 6));
        setShowLoadMore(allUsers.length > 6);
    }, [allUsers]);

    const handleLoadMore = () => {
        const nextVisibleUsers = allUsers.slice(0, visibleUsers.length + 12);
        setVisibleUsers(nextVisibleUsers);
        setShowLoadMore(nextVisibleUsers.length < allUsers.length);
    };

    const handleFilter = (e) => {
        const selectedDesignation = e.target.value;
        setFilterDesignation(selectedDesignation);
        if (selectedDesignation === '') {
            setVisibleUsers(allUsers.slice(0, 6));
            setShowLoadMore(allUsers.length > 6);
        } else {
            const filteredUsers = allUsers.filter(
                (user) => user.designation === selectedDesignation
            );
            setVisibleUsers(filteredUsers.slice(0, 6));
            setShowLoadMore(filteredUsers.length > 6);
        }
    };

    return (
        <div id="mainCont" style={{
            // display: 'flex',

        }}>
            {/* <div
                style=
                {{
                    width: '20%'
                }} id="sb">
                <Sidebar />
            </div> */}

            <div
                style={{
                    border:"1px solid black",
                    // width: '80%',
                    // marginLeft: "5%",
                    // marginTop: "10%",
                    // display: "flex"
                }}
            >
                {/* <div 
                style={{
                    width:"20%",
                    border:"1px solid red"
                }}
                ></div> */}
                <div style={{
                    // width: "100%"
                }}>
                    <div class="pmd-sidebar-overlay"></div>
                    <div id="content" class="pmd-content inner-page admin">
                        <div class="d-flex align-items-center pb-3">
                            <div>
                                <h1>Employees</h1>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb pmd-breadcrumb mb-0">
                                        <li class="breadcrumb-item">
                                            <Link to="/dashboard">Dashboard</Link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            Employees List
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="ml-auto d-flex">
                                <ul class="nav nav-tabs border-0">
                                    <li class="nav-item mr-2">
                                        <a
                                            title="Grid View"
                                            class="btn pmd-btn-fab pmd-ripple-effect nav-link active btn-sm btn-outline-dark"
                                            href="#gridView"
                                            aria-controls="gridView"
                                            role="tab"
                                            data-toggle="tab"
                                        >
                                            <i
                                                onClick={() => {
                                                    setView(true);
                                                }}
                                                class="material-icons"
                                            >
                                                apps
                                            </i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            title="List View"
                                            class="btn btn-outline-dark pmd-btn-fab pmd-ripple-effect nav-link btn-sm"

                                            aria-controls="listView"
                                            role="tab"
                                            data-toggle="tab"
                                        >
                                            <i
                                                onClick={() => {
                                                    setView(false);
                                                }}
                                                class="material-icons"
                                            >
                                                format_list_bulleted
                                            </i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <section class="component-section" id="employee">
                            <div class="card pmd-card">
                                <div class="search-bar card-body">
                                    <div class="row d-flex align-items-center">
                                        <div class="col">
                                            <div class="d-md-block d-none">
                                                <form class="search-form" style={{ marginTop: '-10px' }}>
                                                    <div class="form-row">
                                                        <div class="form-group pmd-textfield pmd-textfield-floating-label col-md-3 mb-0">
                                                            {/* <label>Designation</label> */}
                                                            <Placeholder>Designation</Placeholder>
                                                            <select
                                                                name="select"
                                                                id="designation"
                                                                class="form-control"
                                                                onChange={handleFilter}
                                                                value={filterDesignation}
                                                            >
                                                                <option value="">All</option>
                                                                <option>Managing Director</option>
                                                                <option>Director</option>
                                                                <option>Operations Management Head</option>
                                                                <option>Operations Head & Brand Strategist</option>
                                                                <option>SEO Analyst</option>
                                                                <option>SEO & You Tube Analyst</option>
                                                                <option>Web developer</option>
                                                                <option>Social media manager</option>
                                                                <option>Content Writer</option>
                                                                <option>Client Servicing</option>
                                                                <option>Website Developer</option>
                                                                <option>Accounts cum HR Manager</option>
                                                                <option>Client Servicing cum Content Curator</option>
                                                                <option>Client Servicing cum Social Media Executive</option>
                                                                <option>Management Trainee</option>
                                                                <option>Graphic Designer</option>
                                                                <option>Project Associate</option>
                                                                <option>Senior Content Curator</option>
                                                                <option>Intern SEO Executive</option>
                                                                <option>Executive - Graphics Designer</option>
                                                                <option>Software Developer</option>
                                                                <option>Video Editor cum Graphic Designer</option>
                                                                <option>Photographer</option>
                                                                <option>Intern Web Devloper</option>
                                                                <option>Intern</option>
                                                            </select>
                                                        </div>
                                                        {/* <div class="col-md-1 col-lg-2 mt-4">
                                                            <button class="btn btn-primary pmd-ripple-effect pmd-btn-raised" id="search">
                                                                Go
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </form>
                                                {/* <h3 class="card-title advanced-search-title mb-0">Advanced Search</h3> */}
                                            </div>

                                            <form class="d-flex d-md-none media align-items-center col">
                                                <div class="media-body">
                                                    <div class="input-group pmd-input-group pmd-input-group-icon mb-0">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <i class="material-icons md-dark pmd-sm">search</i>
                                                            </div>
                                                        </div>
                                                        <div class="pmd-textfield pmd-textfield-floating-label">
                                                            <label for="search-field">Search</label>
                                                            <input type="text" class="form-control" id="search-field" aria-describedby="search-field" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-100 d-flex mobile-fixed-btn pmd-z-depth-light-2-2">
                                                    <a
                                                        title="Filter"
                                                        href="javascript:void(0);"
                                                        class="btn btn-primary pmd-ripple-effect pmd-btn-icon mb-0 w-50"
                                                        data-toggle="modal"
                                                        data-target="#advanced-search-modal"
                                                    >
                                                        <i class="pmd-icon-xs"></i> Filter
                                                    </a>
                                                    <a
                                                        title="Sort"
                                                        href="javascript:void(0);"
                                                        class="btn btn-dark pmd-ripple-effect pmd-btn-icon mb-0 w-50"
                                                        data-toggle="modal"
                                                        data-target="#sort-modal"
                                                    >
                                                        <i class="material-icons">sort</i>Sort
                                                    </a>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {view ? (
                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane active" id="gridView">
                                        <div className="row">
                                            {visibleUsers.map((el, i) => {
                                                return <EmployeeCards userData={el} key={i} />;
                                            })}
                                        </div>
                                        {showLoadMore && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <Button className="btn btn-primary" onClick={handleLoadMore}>
                                                        Load More
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="tab-content">  <div role="tabpanel" className="tab-pane active" id="gridView">
                                    <div className="row"><ListEmployee style={{ marginLeft: 'auto' }} /></div></div></div>

                            )}
                        </section>
                    </div>
                    <div class="menu list-view pmd-floating-action" role="navigation">
                        <Link to="/addemployeepage" class="pmd-floating-action-btn btn pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-secondary" data-title="Add Employee">
                            <i class="material-icons pmd-sm">add</i>
                        </Link>
                    </div>

                    {/* <div class="modal pmd-modal fade text-center delete-modal" id="delete_employee_modal" tabindex="-1" role="dialog" aria-labelledby="delete_employee_label" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header justify-content-center">
                                    <i class="material-icons md-danger pmd-icon-circle bg-danger pmd-icon-md">
                                        <strong>error_outline</strong>
                                    </i>
                                </div>
                                <div class="modal-body">
                                    <h2 class="modal-title">Are you sure?</h2>
                                    <p>
                                        Do you really want to delete the employee details? <br /> This process cannot be undone.
                                    </p>
                                    <a href="javascript:void(0);" class="btn btn-outline-dark pmd-btn-raised" title="Cancel" data-dismiss="modal">
                                        Cancel
                                    </a>
                                    <a href="javascript:void(0);" class="btn btn-primary pmd-btn-raised" title="Delete">
                                        Delete
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
